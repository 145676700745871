import { api } from ".";
import type { BrokerOrders } from "types";

export const broker = api
	.enhanceEndpoints({
		addTagTypes: ["orders"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getBrokerOrders: build.query<BrokerOrders, string>({
				query(accountId: string) {
					return {
						url: `/brokers/${accountId}/orders`,
						method: "GET",
					};
				},
				providesTags: ["orders"],
			}),
		}),
	});

export const { useLazyGetBrokerOrdersQuery } = broker;
