import type { ChartCmd, ChartLineObject, ChartPoint } from "types";

export const Line = (update: ChartCmd, current?: ChartCmd): ChartCmd => {
	const {
		points: newPoints,
		append_points: newAppendPoints,
	}: { points: Array<ChartPoint>, append_points: Array<ChartPoint> } =
		update.obj as ChartLineObject;
	
	const cmd = current ? current : update;
	const points = current ? (current.obj as ChartLineObject).points : [];
	
	return {
		...cmd,
		obj: {
			...cmd.obj,
			objId: cmd.obj.objId,
			objtype: "LINE",
			points: newPoints && newPoints.length > 0 ? newPoints : points.concat(newAppendPoints),
		},
	};
};
