import { Loading, TrendChart } from "components";
import { ToastContext, ToastContextType } from "context";
import { useContext, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useExecuteQueryQuery, useGetQueryQuery } from "services";
import { AssetDataItem, ContainerProps } from "types";
import { queryDataToChart } from "utils";

const TrendQuery = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps) => {
	const { queryId, isLoading } = useParams();
	const location = useLocation();
	const { createToast } = useContext(ToastContext) as ToastContextType;
	
	const queryParams = new URLSearchParams(location.search);
	const symbol = queryParams.get("symbol");
	const { data: currentQuery } = useGetQueryQuery(queryId ?? "", {
		skip: !queryId,
	});
	
	const sql =  currentQuery?.code ? currentQuery.code : "SELECT * FROM ticker_aggs LIMIT 15;";
	
	const {
		data: queryExecutionResult,
		isFetching: queryExecutionIsFetching,
		isError: queryExecutionIsError,
	} = useExecuteQueryQuery({ sql }, { skip: sql === "" });
	
	useEffect(() => {
		if (queryExecutionIsError) {
			createToast("Something went wrong when executing the SQL query");
		}
	}, [queryExecutionIsError, createToast]);
	
	useEffect(() => {
		setBreadcrumbs([{ text: `Historic Match ${queryId}/${symbol}` }]);
		setHeaderActions({
			create: [],
		});
	}, [
		setBreadcrumbs,
		setHeaderActions,
		queryId,
		symbol,
	]);
	
	const chartData = useMemo<{ [key: string]: AssetDataItem[] }>(() => {
		if (queryExecutionResult && queryExecutionResult.length > 0) {
			return queryDataToChart(queryExecutionResult);
		}
		
		return {};
	}, [queryExecutionResult]);
	
	return (
		<>
			{(isLoading || queryExecutionIsFetching) && <Loading message="Loading query..." />}
			{Object.keys(chartData).length > 0 && symbol &&
				<TrendChart chartData={{ [symbol]: chartData[symbol] }} showAxis />
			}
		</>
	);
};

export default TrendQuery;