import {
	Annotation,
	Box,
	Candles,
	Line,
	Point,
	Polygon,
	Symbol,
	Volumes,
} from "components";

import type { ReactNode } from "react";
import type {
	ChartAnnotationObject,
	ChartAxisHelpers,
	ChartBoxObject,
	ChartDataBounds,
	ChartLineObject,
	ChartPointObject,
	ChartPolygonObject,
	ChartSymbolObject,
} from "types";

type ChartObjectsProps = {
	axisHelpers?: ChartAxisHelpers,
	bounds: ChartDataBounds,
};

const CandleObjects = ({
	axisHelpers,
	bounds,
}: ChartObjectsProps): ReactNode => {
	if (!axisHelpers) return;
	const { scaleFactor, yMin, yMax } = axisHelpers;
	const { redcandles, greencandles, low, maxvol } = bounds;
	
	return (
		<>
			<Candles
				scaleFactor={scaleFactor}
				redcandles={redcandles}
				greencandles={greencandles}
			/>
			<Volumes
				scaleFactor={scaleFactor}
				redcandles={redcandles}
				greencandles={greencandles}
				yMin={yMin}
				yMax={yMax}
				low={low}
				maxvol={maxvol}
			/>
		</>
	);
};

const NonCandleObjects = ({
	axisHelpers,
	bounds,
}: ChartObjectsProps): ReactNode => {
	if (!axisHelpers) return;
	const { noncandle } = bounds;
	const { scaleFactor } = axisHelpers;
	
	return (noncandle || []).map((obj) => {
		switch (obj.objtype) {
			case "BOX":
				return <Box scaleFactor={scaleFactor} {...obj as ChartBoxObject} key={obj.objId} />;
			case "ANNOTATION":
				return <Annotation scaleFactor={scaleFactor} {...obj as ChartAnnotationObject} key={obj.objId} />;
			case "POINT":
				return <Point scaleFactor={scaleFactor} {...obj as ChartPointObject} key={obj.objId} />;
			case "SYMBOL":
				return <Symbol scaleFactor={scaleFactor} {...obj as ChartSymbolObject} key={obj.objId} />;
			case "LINE":
				return <Line scaleFactor={scaleFactor} {...obj as ChartLineObject} key={obj.objId} />;
			case "POLYGON":
				return <Polygon scaleFactor={scaleFactor} {...obj as ChartPolygonObject} key={obj.objId} />;
			default:
				return null;
		};
	});
};

const ChartObjects = (props: ChartObjectsProps): ReactNode => {
	return (
		<>
			<CandleObjects {...props} />
			<NonCandleObjects {...props} />
		</>
	);
};

export default ChartObjects;
