import { validChartObjectTypes } from "consts";

import type {
	CalcBoundsInput,
	ChartAnnotationObject,
	ChartBoxObject,
	ChartCandleObject,
	ChartCmdObj,
	ChartDataBounds,
	ChartLineObject,
	ChartObjectType,
	ChartPoint,
	ChartPointObject,
	ChartPolygonObject,
	ChartSymbolObject,
	ScaleFactor,
} from "types";

type ObjectBounds = {
	objMinX: number,
	objMaxX: number,
	objMinY: number,
	objMaxY: number,
};

// Type guard function
function isChartObjectType(type: string): type is ChartObjectType {
	return validChartObjectTypes.includes(type as ChartObjectType);
}

const calcAnnotationBounds = (obj: ChartAnnotationObject): ObjectBounds => {
	// TODO: Calculate/determine bounds of text from origin creation point. Needs render info.
	// Obtain once rendered from text.textRenderInfo.visibleBounds
	// See ticket [535]
	const { x, y } = obj.position;
	
	return { objMinX: x, objMaxX: x, objMinY: y, objMaxY: y };
};

const calcBoxBounds = (obj: ChartBoxObject): ObjectBounds => {
	const objMaxX = obj.startPoint.x + obj.width / 2;
	const objMinX = obj.startPoint.x - obj.width / 2;
	const objMaxY = obj.startPoint.y + obj.height / 2;
	const objMinY = obj.startPoint.y - obj.height / 2;
	
	return { objMinX, objMaxX, objMinY, objMaxY };
};

const calcCandleBounds = (obj: ChartCandleObject): ObjectBounds => {
	const { dt_from_ms, dt_to_ms, o, c, h, l } = obj.candle; // eslint-disable-line camelcase
	
	const objMaxX = dt_to_ms / 1000; // eslint-disable-line camelcase
	const objMinX = dt_from_ms / 1000; // eslint-disable-line camelcase
	const objMaxY = Math.max(o, c, h);
	const objMinY = Math.min(o, c, l);
	
	return { objMinX, objMaxX, objMinY, objMaxY };
};

const calcPointsBounds = (points: Array<ChartPoint>): ObjectBounds | null => {
	if (points.length === 0) return null;
	
	const firstPoint = points[0];
	
	let objMaxX = firstPoint.x;
	let objMinX = firstPoint.x;
	let objMaxY = firstPoint.y;
	let objMinY = firstPoint.y;
	
	points.forEach((point) => {
		const x = point.x;
		const y = point.y;
		
		objMinX = Math.min(objMinX || x, x);
		objMaxX = Math.max(objMaxX || x, x);
		objMinY = Math.min(objMinY || y, y);
		objMaxY = Math.max(objMaxY || y, y);
	});
	
	return { objMinX, objMaxX, objMinY, objMaxY };
};

const calcLineBounds = (obj: ChartLineObject): ObjectBounds => {
	const pointsBounds = calcPointsBounds(obj.points);
	
	if (!pointsBounds) {
		throw new Error("Missing object points needed to calculate the Shape.");
	}
	
	return pointsBounds;
};

const calcPointBounds = (obj: ChartPointObject): ObjectBounds => {
	const { x, y } = obj.position;
	
	return { objMinX: x, objMaxX: x, objMinY: y, objMaxY: y };
};

const calcPolygonBounds = (obj: ChartPolygonObject): ObjectBounds => {
	const pointsBounds = calcPointsBounds(obj.points);
	
	if (!pointsBounds) {
		throw new Error("Missing object points needed to calculate the shape.");
	}
	
	const position = obj.position || { x: 0, y: 0 };
	
	return {
		objMinX: pointsBounds.objMinX + position.x,
		objMaxX: pointsBounds.objMaxX + position.x,
		objMinY: pointsBounds.objMinY + position.y,
		objMaxY: pointsBounds.objMaxY + position.y,
	};
};

const calcSymbolBounds = (obj: ChartSymbolObject): ObjectBounds => {
	const { x, y } = obj.position;
	
	return { objMinX: x, objMaxX: x, objMinY: y, objMaxY: y };
};

const OBJECT_CALCULATORS = {
	BOX: (obj: ChartCmdObj) => calcBoxBounds(obj as ChartBoxObject),
	CANDLE: (obj: ChartCmdObj) => calcCandleBounds(obj as ChartCandleObject),
	ANNOTATION: (obj: ChartCmdObj) => calcAnnotationBounds(obj as ChartAnnotationObject),
	POINT: (obj: ChartCmdObj) => calcPointBounds(obj as ChartPointObject),
	SYMBOL: (obj: ChartCmdObj) => calcSymbolBounds(obj as ChartSymbolObject),
	LINE: (obj: ChartCmdObj) => calcLineBounds(obj as ChartLineObject),
	POLYGON: (obj: ChartCmdObj) => calcPolygonBounds(obj as ChartPolygonObject),
};

const calcObjBounds = (obj: ChartCmdObj): ObjectBounds => {
	const calc = OBJECT_CALCULATORS[obj.objtype];
	
	return calc(obj);
};

export const calcBounds = ({
	cmds,
	lowerX = null,
	upperX = null,
}: CalcBoundsInput): ChartDataBounds => {
	const bounds: ChartDataBounds = {
		minX: 0,
		maxX: 0,
		minY: 0,
		maxY: 0,
		low: 0,
		maxvol: 0,
		redcandles: [],
		greencandles: [],
		noncandle: [],
	};
	
	if (cmds === undefined) {
		return bounds;
	}
	
	let len = cmds.length;
	
	while (len--) {
		const { obj, cmdtype } = cmds[len];
		
		if (cmdtype !== "DRAW_OBJ") continue;
		if (!isChartObjectType(obj.objtype)) continue;
		
		const { objMinX, objMaxX, objMinY, objMaxY } = calcObjBounds(obj) || {};
		
		if (
			(objMaxX && upperX && objMinX > upperX) ||
			(objMaxX && lowerX && objMaxX < lowerX)
		)
			continue;
		
		if (obj.objtype === "CANDLE") {
			const { v, l, o, c } = (obj as ChartCandleObject).candle;
			
			bounds.low = l < bounds.low || bounds.low === 0 ? l : bounds.low;
			bounds.maxvol =
				v > bounds.maxvol || !bounds.maxvol ? v : bounds.maxvol;
			
			bounds[o >= c ? "redcandles" : "greencandles"].push(obj as ChartCandleObject);
		} else {
			bounds.noncandle.push(obj as
			| ChartLineObject
			| ChartBoxObject
			| ChartAnnotationObject
			| ChartPointObject
			| ChartPolygonObject
			| ChartSymbolObject
			);
		}
		
		// We don't want to add in any lines to the autoscaling.
		if (obj.objtype === "LINE") continue;
		
		if (!bounds.minX || objMinX < bounds.minX) bounds.minX = objMinX;
		if (!bounds.maxX || objMaxX > bounds.maxX) bounds.maxX = objMaxX;
		if (!bounds.minY || objMinY < bounds.minY) bounds.minY = objMinY;
		if (!bounds.maxY || objMaxY > bounds.maxY) bounds.maxY = objMaxY;
	}
	
	return bounds;
};

type CalcRangeProps = {
	scaleFactor: ScaleFactor,
	position: ChartPoint,
	zoom: number,
	height: number,
	width: number,
};

type Range = {
	xMin: number,
	xMax: number,
	yMin: number,
	yMax: number,
};

export const calcRange = ({
	scaleFactor,
	position,
	zoom,
	height,
	width,
}: CalcRangeProps): Range => {
	const invertX = (x:number) => (x - scaleFactor.translateX) / scaleFactor.scaleX;
	const invertY = (y: number) => (y - scaleFactor.translateY) / scaleFactor.scaleY;
	
	const maxWidth = width / zoom;
	const maxHeight = height / zoom;
	
	return {
		xMin: invertX(position.x - maxWidth / 2),
		xMax: invertX(position.x + maxWidth / 2),
		yMin: invertY(position.y - maxHeight / 2),
		yMax: invertY(position.y + maxHeight / 2),
	};
};

type RecalculateScaleFactorProps = {
	zoom: number,
	height: number,
	minY?: number,
	maxY?: number,
};

type PartialScaleFactor = {
	scaleY: number,
	translateY: number,
};

export const recalculateScaleFactor = ({
	height,
	zoom,
	minY,
	maxY,
}: RecalculateScaleFactorProps): PartialScaleFactor => {
	if (!minY || !maxY) return { translateY: 0, scaleY: 0 };
	
	const maxHeight = height / zoom;
	
	const scaleY = (maxHeight * 0.75) / (maxY - minY);
	const translateY = (-(minY + maxY) * scaleY) / 2;
	
	return { scaleY, translateY };
};
