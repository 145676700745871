import { type Dispatch, type ReactNode, type SetStateAction, Suspense, useMemo, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { ChartCamera, ChartObjects } from "components";
import { OrbitControls } from "@react-three/drei";
import { MOUSE, TOUCH } from "three";
import { RunEventType } from "types";
import {
	CrossHairs,
	HorizontalGridLines,
	Process,
	VerticalGridLines,
	calcBounds,
} from "../helpers";
import { Line } from "./helpers";

import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import type {
	ChartAxisHelpers,
	ChartCmd,
	ChartData,
	MousePos,
	SetChartAxisHelpers,
} from "types";

type ChartProps = {
	cmds: ChartData[],
	mousePos?: MousePos,
	setMousePos: Dispatch<SetStateAction<MousePos | undefined>>,
	axisHelpers?: ChartAxisHelpers,
	setAxisHelpers: SetChartAxisHelpers,
	maxXSteps: number,
	maxYSteps: number,
};

const Chart = ({
	cmds,
	mousePos,
	setMousePos,
	axisHelpers,
	setAxisHelpers,
	maxXSteps,
	maxYSteps,
}: ChartProps): ReactNode => {
	// Refs
	
	const camRef = useRef();
	const orbitalRef = useRef<OrbitControlsImpl>(null);
	
	// Memo
	
	const newEvents = useMemo(() => {
		if (!cmds) return [];
		
		const events: { [key: string]: ChartCmd } = {};
		
		cmds.forEach((ev) => {
			if (ev.eventType !== RunEventType.CHART) return;
			
			const { chartcmd } = ev.params;
			const { objId, objtype } = chartcmd.obj;
			
			if (objtype === "LINE") {
				events[objId] = Line(chartcmd, events[objId]);
			} else if (events[objId] === undefined) {
				events[objId] = chartcmd;
			}
		});
		
		return Object.values(events);
	}, [cmds]);
	
	const bounds = useMemo(
		() =>
			calcBounds({
				cmds: newEvents,
				lowerX: axisHelpers?.xMin,
				upperX: axisHelpers?.xMax,
			}),
		[newEvents, axisHelpers?.xMin, axisHelpers?.xMax]
	);
	
	// Don't render a chart unless there is data to display
	if (!newEvents || newEvents.length === 0) {
		return null;
	}
	
	return (
		<Canvas
			frameloop="demand"
			onMouseMove={(e) => setMousePos(e.nativeEvent as MousePos)}
			onWheel={(e) => setMousePos(e.nativeEvent as any)}
			raycaster={{ params: { Line: { threshold: 5 }, Mesh: {}, LOD: {}, Sprite: {}, Points: { threshold: 1 } } }}
			style={{ position: "absolute", zIndex: 10 }}
		>
			<ChartCamera
				camRef={camRef}
				orbitalRef={orbitalRef}
				bounds={bounds}
				setAxisHelpers={setAxisHelpers}
			/>
			<pointLight position={[0, 0, 0]} color="#f7f3ce" intensity={0.1} />
			<ambientLight color="#fff" intensity={0.85} />
			<Suspense fallback={null}>
				<Process bounds={bounds} setAxisHelpers={setAxisHelpers} />
				<CrossHairs
					mousePos={mousePos}
					bounds={bounds}
					axisHelpers={axisHelpers}
				/>
				<HorizontalGridLines
					axisHelpers={axisHelpers}
					maxSteps={maxYSteps}
					bounds={bounds}
				/>
				<VerticalGridLines
					axisHelpers={axisHelpers}
					maxSteps={maxXSteps}
					bounds={bounds}
				/>
				<ChartObjects axisHelpers={axisHelpers} bounds={bounds} />
			</Suspense>
			<OrbitControls
				ref={orbitalRef}
				mouseButtons={{
					LEFT: MOUSE.PAN,
					MIDDLE: MOUSE.MIDDLE,
					RIGHT: MOUSE.DOLLY,
				}}
				touches={{
					ONE: TOUCH.PAN,
					TWO: TOUCH.PAN,
				}}
				keys={{
					LEFT: "ArrowLeft",
					UP: "ArrowUp",
					RIGHT: "ArrowRight",
					BOTTOM: "ArrowDown",
				}}
				enableDamping={false}
				dampingFactor={0}
				enableRotate={false}
				autoRotate={false}
			/>
		</Canvas>
	);
};

export default Chart;
