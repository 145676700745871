import { type ReactNode, useCallback, useEffect, useMemo, useState } from "react"; //useContext
import { Code as CodeIcon } from "@mui/icons-material";
import {
	useExecuteQueryQuery,
	useGetQueriesQuery,
	useUpdateQueryMutation,
} from "services";
import { getFormattedDate, queryDataToChart } from "utils";
import {
	BigCard,
	LoadQueriesModal,
	Loading,
	PromptBeforeNavigate,
	SaveOrEditSqlModal,
	ShowTrendModal,
	SqlEditor,
	TrendChart,
} from "components";

import type {
	AssetDataItem,
	ContainerProps,
	Query,
} from "types";

const SqlEditorContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	// STATE
	const [currentQuery, setCurrentQuery] = useState<Query | null>(null);
	const [currentSql, setCurrentSql] = useState<string>("");
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [showLoadModal, setShowLoadModal] = useState(false);
	const [showTrendModal, setShowTrendModal] = useState(false);
	const [isEditQuery, setIsEditQuery] = useState(false);
	const [querySymbol, setQuerySymbol] = useState<string>();
	
	const [updateQuery, { isLoading: isUpdatingQuery }] = useUpdateQueryMutation();
	const { queries, lastQuery } = useGetQueriesQuery(undefined, {
		selectFromResult: ({ data }) => ({
			queries: data ?? [],
			lastQuery: data ? [...data].sort((a, b) => b.createdAt - a.createdAt).pop()! : null,
		}),
	});
	const {
		data: queryExecutionResult,
		isFetching: queryExecutionIsFetching,
		isError: queryExecutionIsError,
		refetch: refetchQueryExecutionResult,
	} = useExecuteQueryQuery({ sql: currentSql }, { skip: currentSql === "" });
	
	useEffect(() => {
		if (currentQuery) {
			setCurrentSql(currentQuery.code);
		}
	}, [currentQuery]);
	
	useEffect(() => {
		if (!currentQuery && lastQuery) {
			setCurrentQuery(lastQuery);
		}
	}, [currentQuery, lastQuery]);
	
	const updateQueryCode = useCallback(() => {
		return updateQuery({
			...currentQuery,
			code: currentSql,
		}).unwrap().then((query) => {
			setCurrentQuery(query);
		});
	}, [currentQuery, currentSql, updateQuery]);
	
	useEffect(() => {
		const actions = [
			{
				label: "Load",
				onClick: () => setShowLoadModal(!showLoadModal),
			},
		];
		
		if (currentQuery) {
			actions.push({
				label: "Rename",
				onClick: () => {
					setIsEditQuery(true);
					setShowSaveModal(true);
				},
			});
		}
		
		setBreadcrumbs([
			{ text: "Historic Matches" },
			{ text: currentQuery?.name || "" },
		]);
		setHeaderActions({
			create: [
				{
					label: "Create",
					onClick: () => setShowSaveModal(!showSaveModal),
					createIcon: true,
				},
				{
					label: "Save",
					onClick: updateQueryCode,
					disabled: currentQuery ? currentSql === currentQuery?.code : true,
				},
				{
					label: "Run",
					loading: queryExecutionIsFetching,
					onClick: () => refetchQueryExecutionResult(),
					disabled: !currentQuery,
				},
			],
			actions,
		});
	}, [
		setBreadcrumbs,
		setHeaderActions,
		queryExecutionResult,
		showSaveModal,
		showLoadModal,
		updateQueryCode,
		currentSql,
		currentQuery,
		refetchQueryExecutionResult,
		queryExecutionIsFetching,
	]);
	
	const chartData = useMemo<{ [key: string]: AssetDataItem[] }>(() => {
		if (queryExecutionResult && queryExecutionResult.length > 0) {
			return queryDataToChart(queryExecutionResult);
		}
		
		return {};
	}, [queryExecutionResult]);
	
	const openChartModal = useCallback(() => {
		setShowTrendModal(true);
	}, []);
	
	return (
		<>
			{queryExecutionIsFetching && <Loading message="Loading query..." />}
			{!queryExecutionResult && !queryExecutionIsFetching &&  (
				<BigCard
					icon={CodeIcon}
					message={queryExecutionIsError
						? "Something went wrong when executing the SQL query" : "You don't have any queries."}
					{...(!queryExecutionIsError ? {
						ctaLinkText: "Create one now",
						handleClick: () => setShowSaveModal(!showSaveModal),
					} : {})}
				/>
			)}
			{Object.keys(chartData).length > 0 &&
				<TrendChart
					chartData={chartData}
					thumbnail
					showModal={openChartModal}
					setQuerySymbol={setQuerySymbol}
				/>
			}
			{queries.length > 0 && (
				<SqlEditor
					query={currentSql}
					setQuery={setCurrentSql}
				/>
			)}
			<SaveOrEditSqlModal
				show={showSaveModal}
				handleClose={() => setShowSaveModal(false)}
				isEdit={isEditQuery}
				setIsEdit={() => setIsEditQuery(false)}
				setCurrentQuery={setCurrentQuery}
				currentQuery={currentQuery}
			/>
			<LoadQueriesModal
				show={showLoadModal}
				handleClose={() => setShowLoadModal(false)}
				queries={queries}
				setCurrentQuery={setCurrentQuery}
			/>
			<ShowTrendModal
				show={showTrendModal}
				handleClose={() => setShowTrendModal(false)}
				chartData={chartData}
				matchDate={querySymbol && getFormattedDate(chartData[querySymbol][0].dtFrom, "DD MMM YYYY HH:mm::ss")}
				queryId={currentQuery?.id || ""}
				querySymbol={querySymbol!}
			/>
			<PromptBeforeNavigate
				promptBeforeRedirect={currentQuery ? currentSql !== currentQuery?.code : false}
				handleSave={updateQueryCode}
				saveButtonLoading={isUpdatingQuery}
			/>
		</>
	);
};

export default SqlEditorContainer;
