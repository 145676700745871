import { type ReactNode, useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import { OrthographicCamera } from "@react-three/drei";
import { recalculateScaleFactor } from "./Scaling";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import type { ChartAxisHelpers, ChartCameraProps } from "types";

const camSet = (
	{ camera, orbitControls, x, y, zoom }:
	{ camera: any, orbitControls: OrbitControlsImpl, x: number, y: number, zoom: number }
) => {
	const z = camera.position.z;
	
	camera.position.set(x, y, z);
	camera.lookAt(x, y - 1, z);
	camera.zoom = zoom;
	orbitControls.target.x = x;
	orbitControls.target.y = y;
	orbitControls.target.z = z;
	orbitControls.update();
	camera.updateProjectionMatrix();
	camera.updateMatrix();
};

export const ChartCamera = ({
	camRef,
	orbitalRef,
	setAxisHelpers,
	bounds,
}: ChartCameraProps): ReactNode => {
	const camRefCurrent = camRef?.current;
	const orbitalRefCurrent = orbitalRef?.current;
	
	const [initialCamera, setInitialCamera] = useState<{ x: number, y: number, zoom: number }>();
	
	// Orbital Controls doesn't seem to have fine-grained control of which direction to pan in.
	// This hacky solution allows panning (both x and y) but will immediately set the y position to 0
	// when it changes, to ensure it is 0 every frame and never actually moves.
	useEffect(() => {
		if (!camRefCurrent || !orbitalRefCurrent) return;
		function disableVerticalPan() {
			if (this.target.y !== 0) {
				this.target.y = 0;
				camRefCurrent.position.y = 0;
			}
		}
		if (orbitalRefCurrent && camRefCurrent) {
			orbitalRefCurrent.addEventListener("change", disableVerticalPan);
		}
		
		return () => {
			orbitalRefCurrent.removeEventListener("change", disableVerticalPan);
		};
	}, [camRefCurrent, orbitalRefCurrent]);
	
	useThree((state) => {
		if (bounds === undefined || initialCamera !== undefined) return;
		
		// This sets the initial camera position based on the
		// the "bounds" that are calculated form all the
		// drawing objects that we have to show. This should
		// only ever run "one" per render of the chart.
		const {
			size: { height },
			camera: {
				position: { x, y },
				zoom,
			},
		} = state;
		
		// This gives us something to start with, based on the
		// bounds that we start with.
		const { minX, minY, maxY } = bounds;
		
		const { scaleY, translateY } = recalculateScaleFactor({
			height,
			zoom,
			minY,
			maxY,
		});
		
		setInitialCamera({ x, y, zoom });
		
		setAxisHelpers((prev) => ({
			scaleFactor: {
				scaleY: scaleY || prev?.scaleFactor.scaleY,
				translateY: translateY || prev?.scaleFactor.translateY,
				translateX: -minX,
				scaleX: 1.0,
			},
		} as ChartAxisHelpers));
	});
	
	useEffect(() => {
		if (!initialCamera || !camRefCurrent || !orbitalRefCurrent) {
			return;
		}
		
		camSet({
			camera: camRefCurrent,
			orbitControls: orbitalRefCurrent,
			x: initialCamera.x,
			y: initialCamera.y,
			zoom: initialCamera.zoom,
		});
	}, [initialCamera, camRefCurrent, orbitalRefCurrent]);
	
	return (
		<OrthographicCamera // Ref: https://threejs.org/docs/#api/en/cameras/OrthographicCamera
			makeDefault
			ref={camRef}
			args={[
				// Define a viewing frustum: https://en.wikipedia.org/wiki/Viewing_frustum
				-100, // near - Lowest Z clip for objects (below this they won't appear)
				1000, // far - Highest Z clip for objects (past this they won't appear)
			]}
			position={[0, 0, 10]} // Camera position (where to look from)
		/>
	);
};

export default ChartCamera;
