import { ReactNode, useMemo } from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { getUnixTimestamp } from "utils";
import { InstanceRunsTable, Loading } from "components";
import {
	useGetBacktestInstancesQuery,
	useGetBacktestQuery,
	useLazyGetBrokerOrdersQuery,
	useLazyGetDataForAssetQuery,
	useLazyGetListMetricsQuery,
} from "services";
import Metrics from "./Metrics";
import ScriptOutput from "./ScriptOutput";
import Trading from "./Trading";
import type {
	AssetData,
	AssetDataResolution,
	BrokerOrders,
	ContainerProps,
	Metrics as MetricsType,
	Run,
	RunEvent,
} from "types";

export interface ExtendedBacktest extends Run {
	id: string;
}

type ExtendedBrokerOrder = { [key: string]: BrokerOrders };
export type ExtendedMetrics = { [key: string]: MetricsType[] };
export type ExtendedRunEvent = { [key: string]: RunEvent[] };
export type ExtendedAssetData = { [key: string]: AssetData };

const BacktestResultsContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const [assetData, setAssetData] = useState<ExtendedAssetData>({});
	const [metrics, setMetrics] = useState<ExtendedMetrics>({});
	const [brokerOrders, setBrokerOrders] = useState<ExtendedBrokerOrder>({});
	const [assetResolution, setAssetResolution] = useState<AssetDataResolution>("minute");
	const [selectedRun, setSelectedRun] = useState<string | undefined>();
	const [comparedRuns, setComparedRuns] = useState<Run[]>([]);
	
	let { backtestId, runId } = useParams();
	const navigate = useNavigate();
	
	// RTK Query
	const { data: backtest, isLoading } = useGetBacktestQuery(
		backtestId as string
	);
	const { data: instancesData, isLoading: isLoadingRunHistory } = useGetBacktestInstancesQuery(
		backtestId as string
	);
	
	const runHistory = useMemo(() => {
		if (!instancesData) return [];
		
		return instancesData
			.filter((instance) => instance.latestRun)
			.map((instance) => ({
				...instance.latestRun,
				id: instance.latestRun!.runId,
			} as ExtendedBacktest));
	}, [instancesData]);
	
	const [getMetrics, { isLoading: isLoadingMetrics }] = useLazyGetListMetricsQuery();
	const [getAssetData] = useLazyGetDataForAssetQuery();
	const [getBrokerOrders, { isLoading: isLoadingBrokerOrders }] = useLazyGetBrokerOrdersQuery();
	
	useEffect(() => {
		setBreadcrumbs([
			{ text: "Backtests", url: `/backtests` },
			{ text: `Result for ${backtest?.name || ""}` },
		]);
		setHeaderActions({});
	}, [setBreadcrumbs, setHeaderActions, backtest]);
	
	useEffect(() => {
		if (comparedRuns.length === 0) return;
		comparedRuns.forEach((run) => {
			getMetrics((run as any).brokerAccountId)
				.unwrap()
				.then((metrics) => setMetrics((m) => ({ ...m, [run.runId]: metrics })));
			
		});
	}, [comparedRuns, getMetrics]);
	
	useEffect(() => {
		comparedRuns.forEach((run) => {
			getBrokerOrders((run as any).brokerAccountId)
				.unwrap()
				.then((orders) =>
					setBrokerOrders((m) => ({ ...m, [run.runId]: orders }))
				);
		});
	}, [comparedRuns, getBrokerOrders, setBrokerOrders]);
	
	const assets = useMemo(() => {
		const stocks: { [key: string]: boolean } = {};
		
		comparedRuns.forEach((run) => {
			const orders = brokerOrders[run.runId]?.orders;
			
			if (orders) {
				orders.forEach((ev) => {
					if (ev.order && ev.order.symbol) {
						stocks[ev.order.symbol] = true;
					}
				});
			}
		});
		
		return Object.keys(stocks);
	}, [brokerOrders, comparedRuns]);
	
	useEffect(() => {
		if (!backtest) return;
		if (assets.length > 0) {
			assets.forEach((asset) =>
				getAssetData({
					symbol: asset,
					resolution: assetResolution,
					tsFrom: getUnixTimestamp(backtest.config.interval.start),
					tsTo: getUnixTimestamp(backtest.config.interval.end),
				})
					.unwrap()
					.then((data) =>
						setAssetData((ad) => ({ ...ad, [asset]: data }))
					)
			);
		}
	}, [backtest, assetResolution, assets, getAssetData, setAssetData]);
	
	return (
		<Grid container direction="column" spacing={6} pb={5}>
			{isLoading && <Loading message="Loading results..." />}
			<Grid item>
				<Typography variant="h1" mt={5}>
					Backtest for {backtest?.name || ""}
				</Typography>
			</Grid>
			{!isLoading && (
				<>
					<ScriptOutput
						selectedRun={selectedRun}
						setSelectedRun={setSelectedRun}
						comparedRuns={comparedRuns}
					/>
					<Trading
						assets={assets}
						brokerOrders={
							selectedRun
								? (brokerOrders[selectedRun] as BrokerOrders)
								: undefined
						}
						assetData={assetData}
						assetResolution={assetResolution}
						setAssetResolution={setAssetResolution}
						isLoading={isLoadingBrokerOrders}
						comparedRuns={comparedRuns}
					/>
					<Metrics
						metrics={metrics}
						comparedRuns={comparedRuns}
						isLoading={isLoadingMetrics}
					/>
				</>
			)}
			{!isLoadingRunHistory && runHistory && runId && (
				<InstanceRunsTable
					runHistory={runHistory}
					handleCompareRuns={setComparedRuns}
					runId={runId}
					handleShowRun={(runId) =>
						navigate(`/backtests/${backtestId}/runs/${runId}`)}
				/>
			)}
		</Grid>
	);
};

export default BacktestResultsContainer;
