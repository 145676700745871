import { ReactNode } from "react";
import { DataTable, Loading, Pill } from "components";
import { useGetRunEventsQuery } from "services";
import { RunEventType } from "types";
import type { RunEvent } from "types";

const EVENT_PILL_STYLES = {
	STARTED: "success",
	HEARTBEAT: "primary",
	COMPLETED: "success",
	STDOUT: "primary",
	STDERR: "error",
	CHART: "primary",
	CANCELLED: "error",
	REJECTED: "error",
} as const;

type RunEventsTableProps = {
	instanceId?: string;
	runId?: string;
};

const RunEventsTable = ({
	instanceId,
	runId,
}: RunEventsTableProps): ReactNode => {
	const { events, isLoading } = useGetRunEventsQuery(
		{
			instanceId: instanceId ?? "",
			runId: runId ?? "",
			types: [
				RunEventType.STARTED,
				RunEventType.CANCELLED,
				RunEventType.REJECTED,
				RunEventType.HEARTBEAT,
				RunEventType.COMPLETED,
			],
		},
		{
			skip: !instanceId || !runId,
			selectFromResult: ({ data, isLoading }) => ({
				events: data?.events ?? [],
				isLoading,
			}),
		}
	);
	
	return (
		<>
			{isLoading && <Loading message="Getting events..." />}
			{!isLoading && (
				<DataTable
					getRowId={(row: RunEvent) => row.eventId}
					columns={[
						{
							field: "Event",
							flex: 2,
							sortable: false,
							renderCell: ({ row }: { row: RunEvent }) => (
								<Pill
									table
									text={row.eventType}
									color={
										EVENT_PILL_STYLES[row.eventType] ||
										"success"
									}
									variant={
										row.eventType === RunEventType.HEARTBEAT ? "outlined" : undefined
									}
								/>
							),
						},
						{
							field: "Output",
							valueGetter: ({ row }: { row: RunEvent }) => row.params?.line || "",
							flex: 8,
						},
					]}
					rows={events}
					filter={false}
					search={false}
				/>
			)}
		</>
	);
};

export default RunEventsTable;
