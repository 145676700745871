
import { CHART_OBJECT_POINT_DEFAULTS } from "consts";
import Box from "./Box";

import type { ReactNode } from "react";
import type { ChartBoxObject, ChartPointObject, ScaledObject } from "types";

type PointProps = ChartPointObject & ScaledObject;

const defaultProps = {
	size: CHART_OBJECT_POINT_DEFAULTS.size,
	style: CHART_OBJECT_POINT_DEFAULTS.style,
};

const Point = ({
	objId,
	position,
	size,
	style,
	scaleFactor,
}: PointProps): ReactNode => {
	const point = {
		objId,
		objtype: "BOX",
		height: size,
		width: size,
		depth: 0,
		position: { x: 0, y: 0, z: 0 },
		startPoint: position,
		style: {
			color: style.color,
		},
	} as ChartBoxObject;
	
	return (
		<>
			<Box {...point} scaleFactor={scaleFactor} />
		</>
	);
};

Point.defaultProps = defaultProps;

export default Point;
