import {
	type Dispatch,
	type ReactNode,
	type SetStateAction,
	useMemo,
} from "react";
import { Box, Stack } from "@mui/material";
import { ChartContainer, SelectField } from "components";
import { flattenAssetData, getUnixTimestamp } from "utils";
import { RunEventType } from "types";

import type {
	AssetData,
	AssetDataResolution,
	BrokerOrders,
	Candle,
	ChartData,
} from "types";

const RESOLUTIONS = ["second", "minute", "hour", "day"];

type AssetDataChartContainerProps = {
	assetData?: AssetData | null;
	brokerEvents?: BrokerOrders;
	resolution?: AssetDataResolution;
	setResolution?: Dispatch<SetStateAction<AssetDataResolution>>;
};

const AssetDataChartContainer = ({
	assetData,
	resolution,
	setResolution,
	brokerEvents,
}: AssetDataChartContainerProps): ReactNode => {
	const assetItems = useMemo(
		() => flattenAssetData(assetData || ({} as AssetData)),
		[assetData]
	);
	
	const chartEvents = useMemo(
		() =>
			assetItems.map((assetItem, index) => {
				const candle = {
					...assetItem,
					dt_from_ms: getUnixTimestamp(assetItem.dtFrom) * 1000, // eslint-disable-line camelcase
					dt_to_ms: getUnixTimestamp(assetItem.dtTo) * 1000, // eslint-disable-line camelcase
				};
				
				return {
					eventId: `candle-${index}`,
					eventType: RunEventType.CHART,
					params: {
						chartcmd: {
							cmdtype: "DRAW_OBJ",
							obj: {
								objId: `candle-${index}`,
								objtype: "CANDLE",
								candle: candle as Partial<Candle>,
							},
						},
					},
					runId: "candle",
					timestamp: candle.dt_from_ms / 1000,
				};
			}),
		[assetItems]
	);
	
	const brokerChartEvents = useMemo(() => {
		if (!brokerEvents) return [];
		
		return brokerEvents.orders
			.map((brokerEvent, index: number) => {
				if (brokerEvent.msgtype !== "ORDER_SUCCESS") return null;
				
				if (!assetData) return null;
				
				if (brokerEvent.order.symbol !== assetData.symbol) return null;
				
				return {
					eventId: `order-${index}`,
					eventType: RunEventType.CHART,
					params: {
						chartcmd: {
							cmdtype: "DRAW_OBJ",
							obj: {
								objId: `order-${index}`,
								objtype: "ANNOTATION",
								text: brokerEvent.order.op,
								position: {
									x: getUnixTimestamp(
										brokerEvent.order?.timestamp || null
									),
									y: brokerEvent.order?.unit_price,
									z: 1,
								},
								style: {
									color: "yellow",
									fontSize: 20,
								},
							},
						},
					},
					runId: "annotation",
					timestamp: getUnixTimestamp(
						brokerEvent.order?.timestamp || null
					),
				};
			})
			.filter((x) => x);
	}, [assetData, brokerEvents]);
	
	const allChartEvents = useMemo(() => {
		const allEvents = [...chartEvents, ...brokerChartEvents];
		
		return allEvents;
	}, [chartEvents, brokerChartEvents]);
	
	if (resolution && setResolution ) {
		return (
			<>
				<Stack
					sx={{
						height: "100%",
						width: "100%",
					}}
				>
					<SelectField
						id="resolution"
						modal
						options={RESOLUTIONS.map((resolution) => ({
							label: resolution,
							value: resolution,
						}))}
						name="resolution"
						value={resolution}
						label="Resolution"
						onChange={(e) =>
							setResolution(e.target.value as AssetDataResolution)
						}
					/>
					<Box component="div" height="100%" width="100%">
						<ChartContainer chartEvents={allChartEvents as ChartData[]} />
					</Box>
				</Stack>
			</>
		);
	};
	
	return <ChartContainer chartEvents={chartEvents as Array<ChartData>} />;
	
};

export default AssetDataChartContainer;
