import {
	type ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Code as CodeIcon,
	ListAlt as ListAltIcon,
	Timeline as TimelineIcon,
} from "@mui/icons-material";
import { Grid, SelectChangeEvent, Typography } from "@mui/material";

import { CHARTS, CONSOLE, EVENTS } from "consts";
import {
	ChartContainer,
	CodeEditor,
	Console,
	CreateVersionForm,
	Loading,
	PromptBeforeNavigate,
	RunEventsTable,
	ScriptRunner,
	ScriptVersionsModal,
	SelectField,
	TabButtons,
} from "components";
import { formatDateTime } from "utils";
import {
	useCreateDraftMutation,
	useGetCodeForVersionQuery,
	useGetDraftInstanceQuery,
	useGetLatestCodeQuery,
	useGetRunsQuery,
	useGetScriptQuery,
	useGetVersionsQuery,
	useStartDraftInstanceMutation,
	useUpdateInstanceRunStateMutation,
} from "services";

import type {
	Code,
	ContainerProps,
	RunParams,
	ScriptId,
	ScriptVersionId,
} from "types";

const ScriptEditorContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	// STATE
	const [currentCode, setCurrentCode] = useState<Code>("");
	const [originalCode, setOriginalCode] = useState<Code>("");
	const [isCreatingVersion, setIsCreatingVersion] = useState(false);
	const [runParams, setRunParams] = useState<RunParams | null>(null);
	const [showVersions, setShowVersions] = useState(false);
	const [runId, setRunId] = useState<string>("");
	const [currentTab, setCurrentTab] = useState(CHARTS);
	
	// OTHER HOOKS
	const { scriptId, versionId } = useParams();
	const navigate = useNavigate();
	
	// RTK QUERY
	const { data: script, isLoading: isLoadingScript } = useGetScriptQuery(
		scriptId as ScriptId
	);
	
	const { data: draftInstance } = useGetDraftInstanceQuery(
		{
			scriptId: scriptId as ScriptId,
			runId,
		}
	);
	
	const { runs } = useGetRunsQuery(draftInstance?.instanceId ?? "", {
		skip: !draftInstance,
		selectFromResult: ({ data }) => ({
			runs: [...data?.instanceRuns ?? []].reverse(),
		}),
	});
	
	const { selectedVersion, versions, isLoadingVersions } = useGetVersionsQuery(
		scriptId as ScriptId,
		{
			skip: !versionId,
			selectFromResult: ({ data, isLoading }) => {
				return {
					versions: data?.versions ?? [],
					selectedVersion:
						versionId && data
							? data?.versions.find((v) => v.version === +versionId)!
							: null,
					isLoadingVersions: isLoading,
				};
			},
		}
	);
	
	// Code, latest and perhaps a version that is being loading
	const { latestCode, isLoadingLatest } = useGetLatestCodeQuery(
		scriptId as ScriptId,
		{
			selectFromResult: ({ data, isLoading }) => ({
				latestCode: data?.code ?? "",
				isLoadingLatest: isLoading,
			}),
		}
	);
	
	const { codeForVersion, isLoadingCodeForVersion } = useGetCodeForVersionQuery(
		selectedVersion,
		{
			skip: !selectedVersion,
			selectFromResult: ({ data, isLoading }) => ({
				codeForVersion: data?.code ?? "",
				isLoadingCodeForVersion: isLoading,
			}),
		}
	);
	
	// Controls, all the starting and stopping actions.
	const [startDraftInstance, { isLoading: isWaiting }] = useStartDraftInstanceMutation();
	const [updateInstanceRunState] = useUpdateInstanceRunStateMutation();
	const [triggerCreateDraft, { isLoading: isSubmittingDraft }] = useCreateDraftMutation();
	
	const isLoading =
		isLoadingScript ||
		isLoadingVersions ||
		isLoadingCodeForVersion ||
		isLoadingLatest;
	const isExecuting: boolean = draftInstance
		? draftInstance.state !== "NOT_RUNNING"
		: false;
	const isEditing = !versionId;
	const hasUnsavedChanges = currentCode !== latestCode && isEditing;
	const showSaveVersionButton = currentCode !== originalCode;
	
	// HANDLERS
	const toggleCreateVersion = useCallback(() => {
		setIsCreatingVersion(!isCreatingVersion);
	}, [isCreatingVersion]);
	
	const createDraft = useCallback(
		() => triggerCreateDraft({ scriptId: scriptId ?? "", code: currentCode }),
		[scriptId, currentCode, triggerCreateDraft]
	);
	
	const startDraft = () =>
		startDraftInstance({
			scriptId: scriptId ?? "",
			draftCode: currentCode,
			params: runParams || {},
		});
	
	const stopDraft = () =>
		updateInstanceRunState({
			instanceId: draftInstance?.instanceId ?? "",
			action: "stop",
		});
	
	const handleCodeChanged = (newCode: Code): void => {
		setCurrentCode(newCode);
	};
	
	const handleRunHistoryChange = (e: SelectChangeEvent<unknown>) => {
		setRunId(e.target.value as string);
	};
	
	const handleOpenCodeEditor = (
		readOnly: boolean,
		selectedVersionId?: ScriptVersionId | null
	): void => {
		if (script) {
			const url =
				selectedVersionId && readOnly
					? `/scripts/${script.scriptId}/version/${selectedVersionId}/view`
					: `/scripts/${script.scriptId}/edit`;
			
			navigate(url, { replace: false });
		}
	};
	
	// REACT HOOKS
	useEffect(() => {
		setBreadcrumbs([
			{ text: "Scripts", url: "/scripts" },
			{ text: `${isEditing ? "Edit" : "View"} script` },
		]);
	}, [setBreadcrumbs, isEditing]);
	
	useEffect(() => {
		if (!isEditing) return;
		setHeaderActions({
			create: [
				{
					label: "Commit",
					onClick: toggleCreateVersion,
					disabled: !showSaveVersionButton,
				},
				{
					label: "Save",
					loading: isSubmittingDraft,
					disabled: !hasUnsavedChanges,
					onClick: createDraft,
				},
			],
		});
	}, [
		setHeaderActions,
		hasUnsavedChanges,
		isEditing,
		isSubmittingDraft,
		showSaveVersionButton,
		toggleCreateVersion,
		createDraft,
	]);
	
	useEffect(() => {
		if (isEditing) return;
		setHeaderActions({
			actions: [
				{
					label: "Edit history",
					onClick: () => setShowVersions(true),
				},
			],
		});
	}, [setHeaderActions, setShowVersions, isEditing]);
	
	useEffect(() => {
		if (draftInstance) {
			setRunId(draftInstance.latestRun?.runId ?? "");
		}
	}, [draftInstance]);
	
	useEffect(() => {
		if (runs && runs.length && runId && runParams === null) {
			setRunParams(runs.find(run => run.runId === runId)?.params || {});
		}
	}, [runs, runId, runParams]);
	
	useEffect(() => {
		// Set the "current code" that will be shown in the code editor
		setCurrentCode(codeForVersion || latestCode);
		setOriginalCode((prev) => prev === "" ? latestCode : prev);
	}, [latestCode, codeForVersion]);
	
	return (
		<>
			{isLoading && <Loading message="Loading..." />}
			{!isLoading && script && (
				<>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item md={8}>
							<Typography variant="h1">{script?.name}</Typography>
						</Grid>
						{runs.length > 0 && (
							<Grid item md={4}>
								<SelectField
									id="runHistory"
									options={runs.map((run) => ({
										label: formatDateTime(
											run.createdAt,
											"after"
										),
										value: run.runId,
									}))}
									name="runHistory"
									label="Run history"
									onChange={handleRunHistoryChange}
									value={runId ? runId : ""}
								/>
							</Grid>
						)}
					</Grid>
					<TabButtons
						currentTab={currentTab}
						handleTabChange={(newTab) => setCurrentTab(newTab)}
						tabs={[
							{
								label: CHARTS,
								icon: TimelineIcon,
							},
							{
								label: CONSOLE,
								icon: CodeIcon,
							},
							{
								label: EVENTS,
								icon: ListAltIcon,
							},
						]}
					/>
					<Grid container item height="70vh">
						{currentTab === CHARTS && (
							<ChartContainer instanceId={draftInstance?.instanceId ?? ""} runId={runId} />
						)}
						{currentTab === CONSOLE && (
							<Console
								instanceId={draftInstance?.instanceId}
								runId={runId}
							/>
						)}
						{currentTab === EVENTS && (
							<RunEventsTable
								instanceId={draftInstance?.instanceId}
								runId={runId}
							/>
						)}
					</Grid>
					<Grid
						container
						justifyContent="space-around"
						alignItems="center"
						columnSpacing={4}
						py={4}
					>
						<Grid
							item
							xs={12}
							sm={12}
							md={8}
							sx={{ height: "70vh" }}
						>
							<CodeEditor
								currentCode={currentCode}
								handleUpdateCode={handleCodeChanged}
								readOnly={!isEditing}
								script={script}
								version={versionId}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={4}
							sx={{ height: "80vh" }}
						>
							{isEditing && <ScriptRunner
								isExecuting={isExecuting}
								runParams={runParams || {}}
								start={async() => {
									await createDraft();
									startDraft();
								}}
								stop={stopDraft}
								updateRunParams={setRunParams}
								isWaiting={isWaiting}
								codeChanges={hasUnsavedChanges}
							/>}
						</Grid>
					</Grid>
					<CreateVersionForm
						handleFormClose={toggleCreateVersion}
						code={currentCode}
						scriptId={script.scriptId}
						show={isCreatingVersion}
					/>
					<ScriptVersionsModal
						isLoadingVersions={isLoadingVersions}
						onClose={() => setShowVersions(false)}
						handelShowVersion={(versionId) =>
							handleOpenCodeEditor(
								versionId !== null,
								versionId
							)
						}
						script={script}
						show={showVersions}
						versions={versions}
						currentVersion={Number(versionId)}
					/>
					<PromptBeforeNavigate
						promptBeforeRedirect={hasUnsavedChanges}
						handleSave={createDraft}
						saveButtonLoading={isSubmittingDraft}
					/>
				</>
			)}
		</>
	);
};

export default ScriptEditorContainer;
