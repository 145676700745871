import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
	Info as InfoIcon,
	Newspaper as NewspaperIcon,
	Timeline as TimelineIcon,
	Timeline as TrendingUpIcon,
} from "@mui/icons-material";

import {
	AssetMetadataTable,
	BigCard,
	DataChartContainer,
	GridContainer,
	Loading,
	RequestAssetDataForm,
	RequestAssetDataFormInitialState,
	RequestHarvesterForm,
	TabButtons,
} from "components";
import {
	useDeleteHarvesterRequestMutation,
	useDeleteHistoricalDataRequestMutation,
	useGetAssetQuery,
	useLazyGetDataForAssetQuery,
} from "services";
import { useAppDispatch } from "hooks";
import { setRecentlyViewedAsset } from "store";
import { getJSDate, getNow } from "utils";

import type {
	AssetDataResolution,
	AssetTickerSymbol,
	ContainerProps,
	GetAssetDataRequest,
	Interval,
	StockTimeFrame,
} from "types";

const timeFrameDefaults: StockTimeFrame[] = [
	{
		name: "1H",
		time: "hour",
		value: 1,
		active: true,
		secs: 60 * 60,
	},
	{
		name: "1D",
		time: "day",
		value: 1,
		active: false,
		secs: 60 * 60 * 24,
	},
	{
		name: "1W",
		time: "week",
		value: 1,
		active: false,
		secs: 60 * 60 * 24 * 7,
	},
	{
		name: "1M",
		time: "month",
		value: 1,
		active: false,
		secs: 60 * 60 * 24 * 31,
	},
	{
		name: "3M",
		time: "month",
		value: 3,
		active: false,
		secs: 60 * 60 * 24 * 31 * 3,
	},
	{
		name: "1Y",
		time: "year",
		value: 1,
		active: false,
		secs: 60 * 60 * 24 * 365,
	},
];

const defaultBreadcrumbs = [
	{
		text: "Stock Data",
		url: "/stock-data",
	},
];

const StockDataContainer = ({
	setBreadcrumbs,
	setHeaderActions,
}: ContainerProps): ReactNode => {
	const dispatch = useAppDispatch();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [assetFormData, setAssetFormData] = useState<RequestAssetDataFormInitialState | null>(null);
	const [displayHarvesterRequestForm, setDisplayHarvesterRequestForm] = useState(false);
	const [displayDataRequestForm, setDisplayDataRequestForm] = useState<boolean>(false);
	const [currentTab, setCurrentTab] = useState("Graph");
	const [timeFrame, setTimeFrame] = useState<StockTimeFrame[]>(timeFrameDefaults);
	
	// RTK Query
	const { assetId } = useParams();
	const {
		data: selectedAsset,
		isLoading,
		isError,
	} = useGetAssetQuery(assetId as AssetTickerSymbol);
	
	const [getAssetData, { data: selectedAssetData }] =
		useLazyGetDataForAssetQuery();
	const [deleteHistoricalDataRequest] =
		useDeleteHistoricalDataRequestMutation();
	const [deleteHarvesterRequest] = useDeleteHarvesterRequestMutation();
	
	/**
	 * Toggle forms methods
	 */
	const toggleDrawer = (): void => setIsDrawerOpen(!isDrawerOpen);
	const toggleDataRequestForm = useCallback((
		initialData: RequestAssetDataFormInitialState | null = null
	): void => {
		setAssetFormData(initialData);
		setDisplayDataRequestForm(!displayDataRequestForm);
	}, [displayDataRequestForm]);
	
	const toggleHarvesterRequestForm = useCallback((): void => {
		setDisplayHarvesterRequestForm(!displayHarvesterRequestForm);
	}, [displayHarvesterRequestForm]);
	
	const resolution = {
		"1H": "second",
		"1D": "minute",
		"1W": "minute",
		"1M": "hour",
		"3M": "day",
		"1Y": "day",
	}[timeFrame.find((tf) => tf.active)?.name || "1H"]!;
	
	// CONTEXT & OTHER HOOKS
	const pageTitle =
		!isLoading && selectedAsset ? selectedAsset.name : "Asset Data";
	
	const handleTimeFrameChange = (requestedTimeFrame: string) => {
		let newTimeFrame: StockTimeFrame[] = [];
		
		timeFrame.forEach((frame) => {
			frame.name === requestedTimeFrame
				? newTimeFrame.push({ ...frame, active: true })
				: newTimeFrame.push({ ...frame, active: false });
		});
		setTimeFrame(newTimeFrame);
	};
	
	useEffect(() => {
		if (selectedAsset) {
			setBreadcrumbs([
				...defaultBreadcrumbs,
				{
					text: selectedAsset.ticker,
					type: "pill",
				},
			]);
			
			// Only trigger recently viewed if it was possible to
			// download the ticker data.
			dispatch(setRecentlyViewedAsset(selectedAsset));
		} else {
			setBreadcrumbs(defaultBreadcrumbs);
		}
	}, [
		setBreadcrumbs,
		dispatch,
		selectedAsset,
	]);
	
	useEffect(() => {
		setHeaderActions({
			create: [
				{
					label: "Populate Historical",
					onClick: toggleDataRequestForm,
					createIcon: true,
				},
				{
					label: "Populate Realtime",
					onClick: toggleHarvesterRequestForm,
					createIcon: true,
				},
			],
		});
	}, [
		setHeaderActions,
		toggleDataRequestForm,
		toggleHarvesterRequestForm,
	]);
	
	useEffect(() => {
		if (!selectedAsset) return;
		getAssetData({
			symbol: selectedAsset?.ticker,
			resolution: resolution,
			tsFrom:
				getNow() - (timeFrame.find((tf) => tf.active)?.secs || 60 * 60),
			tsTo: getNow(),
		} as GetAssetDataRequest);
	}, [resolution, timeFrame, selectedAsset, getAssetData]);
	
	return (
		<Stack>
			<Grid container justifyContent="space-between" mt={4}>
				<Grid item md={10} lg={11}>
					<Typography variant="h1">{pageTitle}</Typography>
				</Grid>
			</Grid>
			{isLoading && <Loading message="Loading stock data..." />}
			{!isLoading && isError && (
				<BigCard
					ctaLinkText="Choose one now"
					handleClick={toggleDrawer}
					icon={TrendingUpIcon}
					message="Failed to load asset data."
				/>
			)}
			{!isLoading && selectedAsset && (
				<TabButtons
					currentTab={currentTab}
					handleTabChange={(newTab) => setCurrentTab(newTab)}
					tabs={[
						{
							label: "Graph",
							icon: TimelineIcon,
						},
						{
							label: "News",
							icon: NewspaperIcon,
						},
						{
							label: "Info",
							icon: InfoIcon,
						},
					]}
				/>
			)}
			{!isLoading && selectedAsset && (
				<Stack>
					{currentTab === "Graph" && (
						<>
							<Grid container pt={3} pb={0.5} spacing={3}>
								{timeFrame.map((frame) => {
									return (
										<Grid
											className="asset-data__range-item"
											key={frame.name}
											item
											sx={{
												...(frame.active
													? {
														textDecorationLine:
																"underline",
														textUnderlineOffset:
																"0.9rem",
														textDecorationThickness:
																"0.2rem",
													}
													: { opacity: 0.3 }),
											}}
											onClick={() =>
												handleTimeFrameChange(
													frame.name
												)
											}
										>
											{frame.name}
										</Grid>
									);
								})}
							</Grid>
							<GridContainer>
								<DataChartContainer
									assetData={selectedAssetData}
								/>
							</GridContainer>
						</>
					)}
					{currentTab === "News" && (
						<Stack my={4}>
							<Typography variant="h2">Latest news</Typography>
							<small>When the API is ready</small>
							<Box component="div">
								{/* News tile component here */}
							</Box>
						</Stack>
					)}
					{currentTab === "Info" && (
						<Stack my={4}>
							<p>Company description here</p>
							<p>{`Company number: ${"COMPANY #"}`}</p>
						</Stack>
					)}
				</Stack>
			)}
			{selectedAsset && (
				<AssetMetadataTable
					intervals={selectedAsset.intervals}
					populateBlock={(row: Interval) => {
						toggleDataRequestForm({
							dateFrom: getJSDate(row.tsFrom),
							dateTo: getJSDate(row.tsTo),
							resolution: row.resolution,
						});
					}}
					cancelRequest={(requestId: string) =>
						deleteHistoricalDataRequest(requestId)
					}
					stopHarvesting={(resolution: string) =>
						deleteHarvesterRequest({
							symbol: selectedAsset?.ticker ?? "",
							resolution: resolution as AssetDataResolution,
						})
					}
				/>
			)}
			<RequestAssetDataForm
				handleFormClose={() => setDisplayDataRequestForm(false)}
				selectedAsset={selectedAsset}
				initialState={assetFormData}
				show={displayDataRequestForm}
			/>
			<RequestHarvesterForm
				handleFormClose={() => setDisplayHarvesterRequestForm(false)}
				selectedAsset={selectedAsset}
				show={displayHarvesterRequestForm}
			/>
		</Stack>
	);
};

export default StockDataContainer;
