import {
	type ReactNode,
	useEffect,
} from "react";
import { DataTable, Pill } from "components";
import {
	useGetOrdersQuery,
} from "services";
import { formatDateTimeWithSeconds } from "utils";
import {
	type BrokerOrder,
	type ContainerProps,
	OrderOp,
} from "types";

type SelectFromResult = {
	orders: any;
	isFetching: boolean;
};

const OrdersContainer = ({
	setBreadcrumbs,
}: ContainerProps): ReactNode => {
	const { orders, isFetching } = useGetOrdersQuery(undefined, {
		selectFromResult: ({ data, isFetching }): SelectFromResult => ({
			orders: data ?? [],
			isFetching,
		}),
	});
	
	useEffect(() => {
		setBreadcrumbs([{ text: "Orders", url: "/orders" }]);
	}, [setBreadcrumbs]);
	
	return (
		<DataTable
			getRowId={(row: BrokerOrder) => row.id}
			columns={[
				{
					field: "symbol",
					headerName: "Ticker",
					flex: 1,
				},
				{
					field: "op",
					headerName: "Operation",
					flex: 1,
					renderCell: ({ value }) => (
						<Pill
							color={value === OrderOp.BUY ? "success" : "error"}
							text={value}
							table
						/>
					),
				},
				{
					field: "unit_price",
					headerName: "Unit Price",
					flex: 1,
				},
				{
					field: "units",
					headerName: "Units",
					flex: 1,
				},
				{
					field: "fee",
					headerName: "Fee",
					flex: 1,
				},
				{
					field: "req_timestamp",
					headerName: "Requested At",
					flex: 1,
					valueFormatter: ({ value }) => formatDateTimeWithSeconds(value),
				},
			]}
			rows={orders}
			loading={isFetching}
		/>
	);
};

export default OrdersContainer;
