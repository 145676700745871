import { useMemo, useRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

import { CHART_OBJECT_CANDLE_DEFAULTS } from "consts";

import type { ReactNode } from "react";
import type { Candle, CandleStyle, ChartCandleObject, ScaleFactor } from "types";

type CandlesProps = {
	redcandles: Array<ChartCandleObject>,
	greencandles: Array<ChartCandleObject>,
	scaleFactor: ScaleFactor,
	yMin: number,
	yMax: number,
	low: number,
	maxvol: number,
	style: CandleStyle,
};

const defaultProps = {
	style: CHART_OBJECT_CANDLE_DEFAULTS.style,
};

const buildCandle = (
	candle: Candle,
	scaleFactor: ScaleFactor,
	yMin: number,
	yMax: number,
	maxVol: number,
	candleLow: number,
	style: CandleStyle
) => {
	const { v, dt_from_ms, dt_to_ms } = candle; // eslint-disable-line camelcase
	
	const calcX = (x: number) => scaleFactor.translateX + scaleFactor.scaleX * x;
	const calcY = (y: number) => scaleFactor.translateY + scaleFactor.scaleY * y;
	
	const shape = new THREE.Shape();
	const from = dt_from_ms / 1000; // eslint-disable-line camelcase
	const to = dt_to_ms / 1000; // eslint-disable-line camelcase
	const bodyWidth = (to - from) * 0.25;
	const wickWidth = bodyWidth * style.wickWidthScale;
	const bot = yMin + (yMax - yMin) / 2;
	const top = bot + ((candleLow - yMin) * 0.95 * v) / maxVol;
	
	shape.moveTo(calcX(from + wickWidth + bodyWidth / 2), calcY(bot));
	shape.lineTo(calcX(from + wickWidth + bodyWidth / 2), calcY(top));
	shape.lineTo(calcX(from - wickWidth - bodyWidth / 2), calcY(top));
	shape.lineTo(calcX(from - wickWidth - bodyWidth / 2), calcY(bot));
	shape.moveTo(calcX(from + wickWidth + bodyWidth / 2), calcY(bot));
	
	return shape;
};

const Volumes = ({
	scaleFactor,
	redcandles,
	greencandles,
	yMin,
	yMax,
	low,
	maxvol,
	style,
}: CandlesProps): ReactNode => {
	const ref = useRef<THREE.Group<THREE.Object3DEventMap> | null>(null);
	
	const greenShapes = useMemo(
		() =>
			(greencandles ?? []).map((obj) =>
				buildCandle(obj.candle, scaleFactor, yMin, yMax, maxvol, low, style)
			),
		[greencandles, scaleFactor, yMin, yMax, maxvol, low, style]
	);
	
	const redShapes = useMemo(
		() =>
			(redcandles ?? []).map((obj) =>
				buildCandle(obj.candle, scaleFactor, yMin, yMax, maxvol, low, style)
			),
		[redcandles, scaleFactor, yMin, yMax, maxvol, low, style]
	);
	
	useFrame((state) => {
		const {
			size: { height },
			camera: { zoom },
		} = state;
		
		if (!!ref?.current?.position) {
			ref.current.position.y = -(height / 2) / zoom;
		}
	});
	
	const extrudeSettings = {
		steps: 1,
		depth: 1,
		bevelEnabled: false,
	};
	const greenGeometry = new THREE.ExtrudeGeometry(
		greenShapes,
		extrudeSettings
	);
	const redGeometry = new THREE.ExtrudeGeometry(redShapes, extrudeSettings);
	
	return (
		<group position={[0, 0, 0]} ref={ref}>
			<mesh geometry={greenGeometry}>
				<meshStandardMaterial color={"green"} />
			</mesh>
			<mesh geometry={redGeometry}>
				<meshStandardMaterial color={"red"} />
			</mesh>
		</group>
	);
};

Volumes.defaultProps = defaultProps;

export default Volumes;
