import { type ReactNode, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import { calcRange, recalculateScaleFactor } from "./Scaling";
import type { ChartDataBounds, SetChartAxisHelpers } from "types";

type ProcessProps = {
	setAxisHelpers: SetChartAxisHelpers,
	bounds: ChartDataBounds,
};

const Process = ({ setAxisHelpers, bounds }: ProcessProps): ReactNode => {
	const width = useThree((state) => state.size.width);
	const height = useThree((state) => state.size.height);
	const zoom = useThree((state) => state.camera.zoom);
	const x = useThree((state) => state.camera.position.x);
	const y = useThree((state) => state.camera.position.y);
	
	useMemo(() => {
		const { minY, maxY } = bounds;
		const { translateY, scaleY } = recalculateScaleFactor({
			height,
			zoom,
			minY,
			maxY,
		});
		
		setAxisHelpers((prev) => {
			if (!prev) return prev;
			
			let { scaleFactor } = prev;
			
			if (!scaleFactor) return prev;
			
			scaleFactor.translateY = translateY || scaleFactor.translateY;
			scaleFactor.scaleY = scaleY || scaleFactor.scaleY;
			
			const range = calcRange({
				scaleFactor,
				position: { x, y },
				zoom,
				height,
				width,
			});
			
			return { ...range, scaleFactor, width, height, x, y, zoom };
		});
	}, [width, height, x, y, zoom, bounds, setAxisHelpers]);
	
	return null;
};

export default Process;
