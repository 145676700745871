import { RunEventType } from "types";
import { SocketService, api } from ".";
import type {
	Code,
	DraftInstanceRequest,
	Instance,
	InstanceId,
	InstanceUpdateParams,
	Instances,
	RunParams,
	ScriptId,
} from "types";

type StartDraftInstanceRequest = {
	scriptId: ScriptId;
	draftCode: Code;
	params: RunParams;
};

export const instance = api
	.enhanceEndpoints({
		addTagTypes: ["instances", "instance", "draft_instance", "runs"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getInstances: build.query<Instances, void>({
				query() {
					return {
						url: "/instances",
						method: "GET",
					};
				},
				providesTags: ["instances"],
				async onCacheEntryAdded(
					arg,
					{ updateCachedData, cacheDataLoaded, cacheEntryRemoved }
				) {
					let subscriptionId;
					
					try {
						await cacheDataLoaded;
						subscriptionId = SocketService().subscribeToExecutionMetadata((error, data) => {
							if (error) return;
							updateCachedData((draft: any) => {
								for (let i in draft.instances) {
									if (draft.instances[i].instanceId === data.instanceId) {
										draft.instances[i].state =
											data.eventType === RunEventType.COMPLETED ? "NOT_RUNNING" : "RUNNING";
										// TODO - Update the socket to return started/stopped/created at values in event
										draft.instances[i].latestRun.startedAt = null;
										draft.instances[i].latestRun.stoppedAt = null;
										draft.instances[i].latestRun.runId = data.runId;
										break;
									}
								}
							});
						});
					} catch {}
					
					await cacheEntryRemoved;
					
					if (subscriptionId) {
						SocketService().unsubscribeToExecutionMetadata(subscriptionId);
					}
				},
			}),
			getInstance: build.query<Instance, InstanceId>({
				query(instanceId: InstanceId) {
					return {
						url: `/instances/${instanceId}`,
						method: "GET",
					};
				},
				providesTags: ["instance"],
			}),
			createInstance: build.mutation<Instance, Partial<Instance>>({
				query(body: Partial<Instance>) {
					return {
						url: "/instances",
						method: "POST",
						body,
					};
				},
				invalidatesTags: ["instances"],
			}),
			updateInstance: build.mutation<Instance, Partial<Instance>>({
				query(instance: Partial<Instance>) {
					return {
						url: `/instances/${instance.instanceId}`,
						method: "PATCH",
						body: instance,
					};
				},
				invalidatesTags: ["instances", "instance"],
			}),
			deleteInstance: build.mutation<Instance, InstanceId>({
				query(instanceId: InstanceId) {
					return {
						url: `/instances/${instanceId}`,
						method: "DELETE",
					};
				},
				invalidatesTags: ["instances", "instance"],
			}),
			updateInstanceRunState: build.mutation<Instance, InstanceUpdateParams>({
				query({ instanceId, action }: InstanceUpdateParams) {
					return {
						url: `/instances/${instanceId}/${action}`,
						method: "POST",
					};
				},
				invalidatesTags: ["draft_instance"],
			}),
			getDraftInstance: build.query<Instance, DraftInstanceRequest>({
				query({ scriptId, runId }: DraftInstanceRequest) {
					return {
						url: `/scripts/${scriptId}/draft_instance`,
						method: "GET",
						// The runId isn't needed by the API, but we pass it here to make
						// sure onCacheEntryAdded gets triggered when a new draft instance is ran
						params: {
							runId,
						},
					};
				},
				providesTags: ["draft_instance"],
				onCacheEntryAdded: async(
					arg,
					{
						updateCachedData,
						cacheDataLoaded,
						cacheEntryRemoved,
						getCacheEntry,
					}
				) => {
					let data;
					let subscriptionId;
					
					try {
						await cacheDataLoaded;
						data = (await getCacheEntry()).data;
						
						subscriptionId = SocketService().subscribeToRun(
							data?.latestRun?.runId ?? "",
							(error, data) => {
								if (error) return;
								updateCachedData((draft: any) => {
									draft.state =
										data?.eventType === RunEventType.COMPLETED
											? "NOT_RUNNING"
											: draft.state;
								});
							}
						);
					} catch {}
					
					await cacheEntryRemoved;
					
					if (subscriptionId) {
						SocketService().unsubscribeToRun(data?.latestRun?.runId ?? "", subscriptionId);
					}
				},
			}),
			startDraftInstance: build.mutation<Instance, StartDraftInstanceRequest>({
				query({ scriptId, ...body }: StartDraftInstanceRequest) {
					return {
						url: `/scripts/${scriptId}/draft_instance/start`,
						method: "POST",
						body,
					};
				},
				invalidatesTags: ["draft_instance", "runs"],
			}),
		}),
	});

export const {
	useGetInstancesQuery,
	useGetInstanceQuery,
	useGetDraftInstanceQuery,
	useStartDraftInstanceMutation,
	useUpdateInstanceRunStateMutation,
	useCreateInstanceMutation,
	useDeleteInstanceMutation,
	useUpdateInstanceMutation,
} = instance;
