import { ChartCmd } from "./chart.types";

export type RunId = string;
export type RunEventId = string;

export interface Run {
	instanceId: string;
	runId: RunId;
	createdAt: number;
	startedAt: number;
	stoppingAt: number;
	stoppedAt: number;
	scriptId: string;
	params?: RunParams;
};

export type RunParams = {
	[key: string]: string,
};

export enum RunEventType {
	STARTED = "STARTED",
	HEARTBEAT = "HEARTBEAT",
	STDOUT = "STDOUT",
	STDERR = "STDERR",
	CHART = "CHART",
	COMPLETED = "COMPLETED",
	CANCELLED = "CANCELLED",
	REJECTED = "REJECTED",
}

export type RunEventParams = {
	line?: string,
	seq?: number,
	chartcmd?: ChartCmd,
};

export type RunEvent = {
	eventId: RunEventId,
	eventType: RunEventType,
	params: RunEventParams,
	runId: RunId,
	timestamp: number,
};

export type Runs = {
	instanceId: string,
	runCount: number,
	scriptId: string,
	instanceRuns: Run[],
};

export type RunEvents = {
	eventCount: number,
	runId: string,
	instanceId: string,
	scriptId: string,
	events: RunEvent[],
};

export type RunEventsRequest = {
	instanceId: string,
	runId: RunId,
	types?: string[],
	limit?: number,
	offset?: number,
}

