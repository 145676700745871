import { useEffect, useState } from "react";
import {
	Code as CodeIcon,
	ListAlt as ListAltIcon,
	Timeline as TimelineIcon,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import { CHARTS, CONSOLE, EVENTS } from "consts";
import {
	ChartContainer,
	Console,
	DarkModeSwitch,
	GridContainer,
	RunEventsTable,
	SelectField,
	TabButtons,
} from "components";

import type { Dispatch, ReactNode, SetStateAction } from "react";
import type { Run } from "types";

interface PropsSelectedField {
	selectedRun?: string;
	setSelectedRun: Dispatch<SetStateAction<string | undefined>>;
	comparedRuns: Run[];
}

interface TabsContentProps {
	comparedRuns: Run[];
	children: (props: {
		instanceId: string,
		runId: string,
	}) => ReactNode;
	fixedHeight?: boolean;
}

const SelectedRunField = ({
	comparedRuns,
	selectedRun,
	setSelectedRun,
}: PropsSelectedField) => {
	useEffect(() => {
		if (!selectedRun) {
			setSelectedRun(comparedRuns[0] && comparedRuns[0].runId);
		}
	}, [comparedRuns, setSelectedRun, selectedRun]);
	
	if (!selectedRun) return null;
	
	return (
		<SelectField
			id="selectedRun"
			options={comparedRuns.map((run) => ({
				label: run.runId,
				value: run.runId,
			}))}
			name="selectedRun"
			defaultValue={selectedRun}
			label="Selected Run"
			onChange={(e) => setSelectedRun(e.target.value as string)}
		/>
	);
};

const TabContent = ({
	comparedRuns,
	children,
	fixedHeight,
}: TabsContentProps) => {
	if (comparedRuns.length === 1) {
		return (
			<>
				{children({
					instanceId: comparedRuns[0].instanceId,
					runId: comparedRuns[0].runId,
				})}
			</>
		);
	};
	
	return (
		<Grid container item spacing={4} height="100%">
			{comparedRuns.map((run) => (
				<Grid
					item
					width="100%"
					sx={{
						flexBasis: comparedRuns.length === 1 ? "100%" : "50%",
						...(fixedHeight ? { height: "100%" } : {}),
					}}
				>
					{children({
						instanceId: run.instanceId,
						runId: run.runId,
					})}
				</Grid>
			))}
		</Grid>
	);
};

const ScriptOutput = ({
	selectedRun,
	setSelectedRun,
	comparedRuns,
}: PropsSelectedField): ReactNode => {
	const [currentOutputTab, setCurrentOutputTab] = useState(CHARTS);
	const [isDarkMode, setIsDarkMode] = useState(true);
	
	return (
		<Grid container item direction="column">
			<Grid item>
				<Typography variant="h2">Output</Typography>
			</Grid>
			<Grid item>
				<TabButtons
					currentTab={currentOutputTab}
					handleTabChange={(newTab) => setCurrentOutputTab(newTab)}
					tabs={[
						{
							label: CHARTS,
							icon: TimelineIcon,
						},
						{
							label: CONSOLE,
							icon: CodeIcon,
						},
						{
							label: EVENTS,
							icon: ListAltIcon,
						},
					]}
				/>
			</Grid>
			<Grid container item direction="column" spacing={1}>
				{comparedRuns.length === 1 && <Grid item>
					<SelectedRunField
						comparedRuns={comparedRuns}
						selectedRun={selectedRun}
						setSelectedRun={setSelectedRun}
					/>
				</Grid>}
				<DarkModeSwitch toggle={() => setIsDarkMode(!isDarkMode)} isDarkMode={isDarkMode} />
				<GridContainer>
					{currentOutputTab === CHARTS && (
						<TabContent comparedRuns={comparedRuns}>
							{({ instanceId, runId }) => <ChartContainer instanceId={instanceId} runId={runId} />}
						</TabContent>
					)}
					{currentOutputTab === EVENTS && (
						<TabContent
							comparedRuns={comparedRuns}
							fixedHeight
						>
							{({ instanceId, runId }) => <RunEventsTable instanceId={instanceId} runId={runId} />}
						</TabContent>
					)}
					{currentOutputTab === CONSOLE && (
						<TabContent
							comparedRuns={comparedRuns}
							fixedHeight
						>
							{({ instanceId, runId }) => <Console
								instanceId={instanceId}
								runId={runId}
								showInGrid
								overrideColor={isDarkMode}
							/>}
						</TabContent>
					)}
				</GridContainer>
			</Grid>
		</Grid>
	);
};

export default ScriptOutput;
