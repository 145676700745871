// ** Axis Display **

import { ChartObjectType } from "types";

// Axis label alignment helpers
export const CHART_AXIS_Y_STEPHEIGHT = 40; // Acommodated height of a Y axis label
export const CHART_AXIS_X_STEPWIDTH = 80; // Acommodated width of an X axis label
export const DEFAULT_X_DIVISIONS = 4;
export const DEFAULT_Y_DIVISIONS = 4;

// ** Chart Objects **

// Common Object Defaults
export const CHART_OBJECT_DEFAULTS = {
	scale: { x: 1, y: 1, z: 1 },
	rotation: { x: 0, y: 0, z: 0 },
};

// Box
export const CHART_OBJECT_BOX_DEFAULTS = {
	scale: CHART_OBJECT_DEFAULTS.scale,
	rotation: CHART_OBJECT_DEFAULTS.rotation,
	style: { color: "gold" },
};

// Candle
export const CHART_OBJECT_CANDLE_DEFAULTS = {
	style: {
		positiveCandleColor: "#559f86",
		negativeCandleColor: "#f45e5e",
		wickWidthScale: 0.2,
	},
};

// Annotation
export const CHART_OBJECT_ANNOTATION_DEFAULTS = {
	font: "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxM.woff", // Roboto
	fontSize: 5,
	style: {
		color: "gold",
		fillOpacity: 1,
		outlineColor: "black",
		outlineWidth: 0.5,
	},
};

// Point
export const CHART_OBJECT_POINT_DEFAULTS = {
	size: 5,
	style: { color: "gold" },
};

// Symbol
export const CHART_OBJECT_SYMBOL_DEFAULTS = {
	scale: CHART_OBJECT_DEFAULTS.scale,
	rotation: CHART_OBJECT_DEFAULTS.rotation,
	style: {
		fillColor: "gold",
		fillOpacity: 1,
		outlineColor: "black",
		outlineWidth: 0.5,
	},
};

// Line
export const CHART_OBJECT_LINE_DEFAULTS = {
	scale: CHART_OBJECT_DEFAULTS.scale,
	rotation: CHART_OBJECT_DEFAULTS.rotation,
	style: {
		color: "gold",
		lineWidth: 2,
		dashed: false,
	},
};

// Polygon
export const CHART_OBJECT_POLYGON_DEFAULTS = {
	scale: CHART_OBJECT_DEFAULTS.scale,
	rotation: CHART_OBJECT_DEFAULTS.rotation,
	style: {
		color: "gold",
	},
	
	// Extrude settings
	extrudeSettings: {
		steps: 2,
		depth: 5,
		bevelEnabled: true,
		bevelThickness: 1,
		bevelSize: 1,
		bevelOffset: 0,
		bevelSegments: 1,
	},
};

export const validChartObjectTypes: ChartObjectType[] = [
	"BOX",
	"CANDLE",
	"ANNOTATION",
	"POINT",
	"SYMBOL",
	"LINE",
	"POLYGON",
];
