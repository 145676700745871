import { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import WrenchIcon from "@mui/icons-material/PlumbingRounded";

import {
	CHART_AXIS_X_STEPWIDTH,
	CHART_AXIS_Y_STEPHEIGHT,
	DEFAULT_X_DIVISIONS,
	DEFAULT_Y_DIVISIONS,
} from "consts";
import { useGetRunEventsQuery } from "services";
import { BigCard, Chart } from "components";
import { XAxis } from "components/common/chart/helpers/XAxis";
import { YAxis } from "components/common/chart/helpers/YAxis";
import { RunEventType } from "types";

import type { ReactNode } from "react";
import type {
	ChartAxis,
	ChartAxisHelpers,
	ChartData,
	InstanceId,
	MousePos,
	RunId,
} from "types";

type ChartContainerProps = {
	axis: {
		bottom: ChartAxis;
		right: ChartAxis;
	};
	chartEvents?: Array<ChartData> | null;
	instanceId?: InstanceId;
	runId?: RunId;
};

const defaultProps = {
	axis: {
		bottom: { enabled: true, timestamps: true },
		right: { enabled: true, timestamps: false },
	},
};

const ChartContainer = ({
	axis,
	chartEvents,
	instanceId,
	runId,
}: ChartContainerProps): ReactNode => {
	const [axisHelpers, setAxisHelpers] = useState<ChartAxisHelpers>();
	const [mousePos, setMousePos] = useState<MousePos>();
	
	// Calculate divisions for axis and gridlines} - default to 4
	const maxXSteps = axisHelpers
		? Math.floor(axisHelpers.width / CHART_AXIS_X_STEPWIDTH)
		: DEFAULT_X_DIVISIONS;
	const maxYSteps = axisHelpers
		? Math.floor(axisHelpers.height / CHART_AXIS_Y_STEPHEIGHT) / 2
		: DEFAULT_Y_DIVISIONS;
	
	const { data } = useGetRunEventsQuery(
		{
			types: [RunEventType.CHART],
			instanceId: instanceId ?? "",
			runId: runId ?? "",
		},
		{
			skip: !instanceId || !runId || runId === "",
			selectFromResult: ({ data }) => ({
				data: data?.events ?? [],
			}),
		}
	);
	
	const events = useMemo(() => {
		if (data.length > 0) {
			return data as ChartData[];
		} else if (chartEvents && chartEvents.length > 0) {
			return chartEvents;
		}
		
		return [] as ChartData[];
	}, [ chartEvents, data ]);
	
	if (events.length === 0) {
		return (
			<BigCard
				icon={WrenchIcon}
				message="Visualisation of results goes here"
			/>
		);
	}
	
	// TODO: is 0.5 too small for the YAxis?
	return (
		<Grid
			container
			item
			direction="column"
			sx={{ userSelect: "none", height: "100%" }}
		>
			<Grid container item direction="row" xs>
				<Grid
					item
					xs={11}
					sx={(theme) => ({
						position: "relative",
						border: `1px solid ${theme.palette.chartBorder.main}`,
					})}
				>
					<Chart
						cmds={events}
						mousePos={mousePos}
						setMousePos={setMousePos}
						axisHelpers={axisHelpers}
						setAxisHelpers={setAxisHelpers}
						maxXSteps={maxXSteps}
						maxYSteps={maxYSteps}
					/>
				</Grid>
				<Grid container item xs={1}>
					{axis.right.enabled && (
						<YAxis
							settings={axis.right}
							axisHelpers={axisHelpers}
							maxSteps={maxYSteps}
							mousePos={mousePos}
							label={axis.right.label || undefined}
						/>
					)}
				</Grid>
			</Grid>
			<Grid container item xs={1}>
				{axis.bottom.enabled && (
					<XAxis
						axisHelpers={axisHelpers}
						maxSteps={maxXSteps}
						mousePos={mousePos}
						label={axis.bottom.label || undefined}
					/>
				)}
				<Grid item xs={1} />
			</Grid>
		</Grid>
	);
};

ChartContainer.defaultProps = defaultProps;

export default ChartContainer;
