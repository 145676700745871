import { Dispatch, SetStateAction } from "react";

import { Modal, TrendChart } from "components";

import { AssetDataItem } from "types";
import { Button } from "@mui/material";

interface Props {
	show: boolean;
	handleClose: Dispatch<SetStateAction<boolean>>;
	chartData: { [key: string]: AssetDataItem[] };
	matchDate?: string;
	queryId: string;
	querySymbol: string;
};

export default function ShowTrendModal({
	show,
	handleClose,
	chartData,
	matchDate,
	queryId,
	querySymbol,
}: Props) {
	
	return (
		<Modal
			title={`Historic match on ${matchDate}`}
			handleClose={() => handleClose(false)}
			show={show}
			size="xl"
			dynamicTitle={
				<Button
					href={`/sql/${queryId}?symbol=${querySymbol}`}
					variant="outlined"
					color="outline"
				>
					Open in new tab
				</Button>
			}
		>
			<TrendChart chartData={{ [querySymbol]: chartData[querySymbol] }} showAxis />
		</Modal>
	);
}