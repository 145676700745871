import { type ReactNode, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Link,
} from "@mui/material";

import {
	Alert,
	KeyValuePairForm,
	Loading,
	Modal,
	SelectField,
	TextField,
} from "components";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
	useCreateInstanceMutation,
	useGetScriptsQuery,
	useGetVersionsQuery,
	useUpdateInstanceMutation,
} from "services";
import { CreateAndEditInstanceFormSchema } from "./CreateAndEditInstanceForm.schema";

import type {
	CreateInstanceRequest,
	EditInstanceRequest,
	Instance,
	KeyValuePair,
	ScriptVersion,
} from "types";

type CreateAndEditInstanceFormProps = {
	handleFormClose: () => void;
	instance?: Instance;
	scriptId?: string;
	show: boolean;
};

const CreateAndEditInstanceForm = ({
	instance,
	handleFormClose,
	scriptId,
	show,
}: CreateAndEditInstanceFormProps): ReactNode => {
	// if instance is not null the form is edit
	const initialValues = !!instance
		? {
			...instance,
			versionId: instance.commitSHA,
			scriptId: scriptId ?? instance.folderId,
		}
		: {
			name: "",
			scriptId: scriptId ?? "",
			versionId: "",
			params: {},
		};
	
	const handleFormSubmit = (form: CreateInstanceRequest | EditInstanceRequest, create: boolean) => {
		const instance = { ...form, commitSHA: form.versionId };
		
		(create ? createInstance(instance) : updateInstance(instance))
			.unwrap()
			.then(() => handleFormClose())
			.catch((e) => setFormError(e.message));
	};
	
	const formik = useFormik({
		initialValues,
		validationSchema: CreateAndEditInstanceFormSchema,
		onSubmit: (data) => handleFormSubmit(data, instance === undefined),
		validateOnChange: true,
		enableReinitialize: true,
	});
	
	const [formError, setFormError] = useState<string>("");
	const [versionsMissingError, setVersionsMissingError] = useState<boolean>(false);
	
	const [createInstance, { isLoading: isCreating }] = useCreateInstanceMutation();
	const [updateInstance, { isLoading: isUpdating }] = useUpdateInstanceMutation();
	const { data: scripts, isLoading: isLoadingScripts } = useGetScriptsQuery();
	const { versions } = useGetVersionsQuery(formik.values.scriptId, {
		skip: formik.values.scriptId === "",
		selectFromResult: ({ data }) => ({
			versions: { [formik.values.scriptId]: data?.versions },
		}),
	});
	
	const handleUpdatePairs = (p: Array<KeyValuePair>): void => {
		const handleValidatePairs = (p: KeyValuePair[]) => {
			let isValid = true;
			const uniqueKeys: string[] = [];
			const errors = p.map(({ key, value }) => {
				if (key === "") {
					isValid = false;
					
					return { key: "Key is required" };
				}
				if (uniqueKeys.indexOf(key) !== -1) {
					isValid = false;
					
					return { key: "Duplicate key" };
				}
				uniqueKeys.push(key);
				
				return {};
			});
			
			return { isValid, errors };
		};
		
		setPairs(p);
		
		const { isValid, errors } = handleValidatePairs(p);
		
		setPairErrors(errors);
		setShowFormError(!isValid);
		if (isValid) {
			const paramsObj = p.reduce((prev, { key, value }) => {
				prev[key] = value;
				
				return prev;
			}, {} as { [key: string]: string });
			
			formik.setFieldValue("params", paramsObj);
		}
	};
	
	useEffect(() => {
		if (!instance) return;
		
		setPairs(
			Object.entries(instance?.params || {}).map(([key, value]) => ({
				key,
				value,
			}))
		);
	}, [instance]);
	
	const [pairs, setPairs] = useState<KeyValuePair[]>([]);
	const [pairErrors, setPairErrors] = useState<
	(
	| {
		key: string;
	}
	| {
		key?: undefined;
	}
	)[]
	>([]);
	const [showFormError, setShowFormError] = useState(false);
	
	useEffect(() => {
		if (!versions) return;
		
		let versionsForScript: boolean =
		(versions[formik.values.scriptId] as ScriptVersion[])?.length > 0 || false;
		
		if (versionsForScript) {
			setVersionsMissingError(false);
		} else {
			setVersionsMissingError(true);
		}
	}, [JSON.stringify(versions), formik.values.scriptId]);
	
	return (
		<Modal
			title={`${!!instance ? "Edit" : "Create"} an instance`}
			handleClose={handleFormClose}
			show={show}
			onSave={formik.handleSubmit}
			saveButtonText={!!instance ? "Update" : "Create"}
			isValid={formik.dirty && formik.isValid && !showFormError}
			isSubmitting={isCreating || isUpdating}
		>
			{formError && <Alert>{formError}</Alert>}
			{isLoadingScripts ? (
				<Loading message="Loading scripts..." />
			) :(
				<form onSubmit={formik.handleSubmit}>
					<TextField
						modal
						id="name"
						name="name"
						label="Instance name"
						placeholder="Enter a name to identify this instance..."
						value={formik.values.name}
						onChange={formik.handleChange}
						error={
							formik.touched.name && Boolean(formik.errors.name)
						}
						helperText={
							formik.touched.name && formik.errors.name
								? formik.errors.name
								: undefined
						}
					/>
					{scripts?.length === 0 && (
						<Alert type="info">
							You need to create a script before creating an
							instance.{" "}
							<Link component={RouterLink} to="/scripts">
								Create one now
							</Link>
						</Alert>
					)}
					{scripts && scripts?.length > 0 && (
						<SelectField
							id="scriptId"
							modal
							options={scripts.map((script) => ({
								label: script.name,
								value: script.scriptId,
							}))}
							name="scriptId"
							value={formik.values.scriptId}
							label="Script to be run"
							onChange={(e) => {
								formik.setFieldValue("versionId", "");
								formik.handleChange(e);
							}}
							error={
								formik.touched.scriptId &&
								Boolean(formik.errors.scriptId)
							}
							helperText={
								formik.touched.scriptId &&
								formik.errors.scriptId
							}
						/>
					)}
					{versionsMissingError && (
						<Alert type="info">
							You need to save a version of this script before
							creating an instance.{" "}
							<Link
								color="inherit"
								component={RouterLink}
								to={`/scripts/${formik.values.scriptId}/edit?readonly=false`}
							>
								Go to the scripts editor
							</Link>
						</Alert>
					)}
					{formik.values.scriptId &&
						versions &&
						!versionsMissingError && (
						<SelectField
							id="versionId"
							modal
							options={(
								(versions.hasOwnProperty(
									formik.values.scriptId
								) &&
										(
											versions as {
												[key: string]: ScriptVersion[];
											}
										)[formik.values.scriptId]) ||
									[]
							).map((version) => ({
								label: version.commitMsg,
								value: version.commitSHA,
							}))}
							name="versionId"
							value={formik.values.versionId}
							label="Version to be run"
							onChange={formik.handleChange}
							error={
								formik.touched.versionId &&
									Boolean(formik.errors.versionId)
							}
							helperText={
								formik.touched.versionId &&
									formik.errors.versionId
							}
						/>
					)}
					<Accordion
						square
						elevation={0}
						sx={{
							background: "transparent",
							border: 0,
							".MuiAccordionSummary-root": { pl: 0, pr: 1 },
						}}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							Parameters
						</AccordionSummary>
						<AccordionDetails>
							<KeyValuePairForm
								errors={pairErrors}
								pairs={pairs}
								onUpdatePairs={handleUpdatePairs}
							/>
						</AccordionDetails>
					</Accordion>
				</form>
			)}
		</Modal>
	);
};

export default CreateAndEditInstanceForm;
