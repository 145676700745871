import { Grid, IconButton, Typography } from "@mui/material";
import { Fullscreen as FullscreenIcon } from "@mui/icons-material";
import { ChartContainer } from "components";
import { formatDateTime, getUnixTimestamp } from "utils";
import { type AssetDataItem, type ChartData, RunEventType } from "types";
import type { Dispatch, SetStateAction } from "react";

export default function TrendChart({
	chartData,
	thumbnail = false,
	showAxis = false,
	showModal = null,
	setQuerySymbol = null,
}: {
	chartData: {
		[key: string]: AssetDataItem[];
	};
	thumbnail?: boolean;
	showAxis?: boolean;
	showModal?: Dispatch<SetStateAction<boolean>> | null;
	setQuerySymbol?: Dispatch<SetStateAction<string | undefined>>| null;
}) {
	const chartEvents = (events: any[]): ChartData[] =>
		events.map((assetItem, index) => {
			const candle = {
				...assetItem,
				dt_from_ms: getUnixTimestamp(assetItem.dtFrom) * 1000, // eslint-disable-line camelcase
				dt_to_ms: getUnixTimestamp(assetItem.dtTo) * 1000, // eslint-disable-line camelcase
			};
			
			return {
				eventId: `candle-${index}`,
				eventType: RunEventType.CHART,
				params: {
					chartcmd: {
						cmdtype: "DRAW_OBJ",
						obj: {
							objId: `candle-${index}`,
							objtype: "CANDLE",
							candle,
						},
					},
				},
				runId: "candle",
				timestamp: candle.dt_from_ms / 1000,
			};
		});
	
	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="stretch"
				gap={6}
				pb={6}
			>
				{Object.keys(chartData).map((key, _, arr) => (
					<Grid
						item
						key={key}
						sm={12}
						md={arr.length === 1 ? 12 : 3}
					>
						<Grid container direction="column">
							<Grid item height="40vh">
								<ChartContainer
									chartEvents={chartEvents(chartData[key]
										// TO DO we will need to select what trend to render at
										// some point as we cna have day, hour, minute or second
										.filter(e => e.aggLevel === "day"))}
									axis={{
										bottom: { enabled: showAxis, timestamps: showAxis },
										right: { enabled: showAxis, timestamps: showAxis },
									}}
								/>
							</Grid>
							{thumbnail && showModal && setQuerySymbol && (
								<Grid
									item
									container
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography color="text.secondary">
										{formatDateTime(chartData[key][0].dtFrom, "after")}
									</Typography>
									<IconButton
										onClick={() => {
											showModal(true);
											setQuerySymbol(key);
										}}
									>
										<FullscreenIcon sx={{ color: "text.secondary" }} />
									</IconButton>
								</Grid>
							)}
						</Grid>
					</Grid>
				))}
			</Grid>
		</>
	);
}