import { Box, Grid } from "@mui/material";

import { getFormattedDate } from "utils";
import { sigPointsTs } from "./SignificantPoints";

import type { ReactNode } from "react";
import type { ChartAxisHelpers, MousePos } from "types";

interface TimestampMap {
	ts: number;
	timeF: string;
	timeF2: string;
	day: string;
	month: string;
	year: string;
}

const ALL_KEYS = ["timeF", "day", "month", "year"];

type AxisProps = {
	mousePos?: MousePos,
	axisHelpers?: ChartAxisHelpers,
	maxSteps: number,
	label?: string,
};

const formatDateToTime = (r: Date) => {
	const formatted = getFormattedDate(r, "HH:mm:ss");
	
	if (formatted.slice(-3) === ":00") {
		return formatted.slice(0, -3);
	}
	
	return formatted;
};

const mapTimestamp = (r: number): TimestampMap => {
	const currentDate = new Date(r * 1000);
	
	return {
		ts: r,
		timeF: formatDateToTime(currentDate),
		timeF2: formatDateToTime(currentDate),
		day: getFormattedDate(currentDate, "ddd DD"),
		month: getFormattedDate(currentDate, "MMM"),
		year: getFormattedDate(currentDate, "YYYY"),
	};
};

export const XAxis = ({
	mousePos,
	axisHelpers,
	maxSteps,
	label,
}: AxisProps): ReactNode => {
	if (!axisHelpers) {
		return null;
	}
	
	const { xMin, xMax, width } = axisHelpers;
	const { layerX } = mousePos || { layerX: 0 };
	const xPos = xMin + layerX / (width / (xMax - xMin));
	
	const results = sigPointsTs({
		minV: xMin,
		maxV: xMax,
		maxDivisions: maxSteps,
	});
	
	const mapped: TimestampMap[] = results.map((r) => mapTimestamp(r));
	
	if (mapped.length === 0) return null;
	
	const skipLabels: { [key:string]: boolean } = {};
	
	ALL_KEYS.forEach((key) => {
		if ([...new Set(mapped.map((result) => result[key as keyof TimestampMap]))].length <= 1) {
			skipLabels[key] = true;
		}
	});
	const labelKeys = ALL_KEYS.filter((key) => !skipLabels[key]);
	const skippedLabelKeys = ALL_KEYS.filter((key) => skipLabels[key]);
	const mappedCrosshair = !isNaN(xPos) && mapTimestamp(xPos);
	
	return (
		<Grid
			container
			item
			xs
			direction="column"
			sx={{ position: "relative", mt: 1 }}
		>
			<Grid container item direction="row" xs sx={{ height: 0 }}>
				{mapped.map((result, stepIndex) => {
					return (
						<Grid
							item
							key={stepIndex}
							sx={{
								position: "absolute",
								translate: "-45% 0",
								left:
									(axisHelpers.width * (result.ts - xMin)) /
									(xMax - xMin),
							}}
						>
							{labelKeys.map((key) => result[key as keyof TimestampMap]).join(" ")}
						</Grid>
					);
				})}
			</Grid>
			<Grid container item justifyContent="center" xs={2} sx={{ height: 0 }}>
				<Grid item>
					{label
						? label.toLocaleUpperCase()
						: skippedLabelKeys
							.map((key) => (mapped[0] ? mapped[0][key as keyof TimestampMap] : ""))
							.join(" ")}
				</Grid>
			</Grid>
			{mappedCrosshair && (
				<Box
					component="div"
					alignItems="center"
					sx={{
						position: "absolute",
						backgroundColor: "green",
						translate: "-45% 0",
						zIndex: 15,
						left:
							(axisHelpers.width * (mappedCrosshair.ts - xMin)) /
							(xMax - xMin),
					}}
				>
					{labelKeys.map((key) => mappedCrosshair[key as keyof TimestampMap]).join(" ")}
				</Box>
			)}
		</Grid>
	);
};
