import { Box, Grid } from "@mui/material";
import { sigPointsNum } from "./SignificantPoints";

import type { ReactNode } from "react";
import type { ChartAxis, ChartAxisHelpers, MousePos } from "types";

type AxisProps = {
	settings?: ChartAxis,
	axisHelpers?: ChartAxisHelpers,
	mousePos?: MousePos,
	maxSteps: number,
	label?: string,
};

export const YAxis = ({
	axisHelpers,
	mousePos,
	maxSteps,
	label,
}: AxisProps): ReactNode => {
	if (!axisHelpers) {
		return null;
	}
	
	const { yMin, yMax, height } = axisHelpers;
	const { layerY } = mousePos || { layerY: 0 };
	const yPos = yMax - layerY / (height / (yMax - yMin));
	
	const results = sigPointsNum({
		minV: yMin,
		maxV: yMax,
		maxDivisions: maxSteps,
	});
	
	return (
		<Grid
			container
			item
			direction="row"
			sx={{ position: "relative", ml: 1 }}
		>
			<Grid item xs>
				{results.map((result, stepIndex) => {
					return (
						<Grid
							item
							key={stepIndex}
							sx={{
								position: "absolute",
								translate: "0 45%",
								bottom:
									(axisHelpers.height *
										(parseFloat(result) - yMin)) /
									(yMax - yMin),
							}}
						>
							{result}
						</Grid>
					);
				})}
			</Grid>
			{layerY ? (
				<Box
					component="div"
					sx={{
						position: "absolute",
						backgroundColor: "green",
						translate: "0 45%",
						bottom: height - layerY,
					}}
				>
					{yPos.toFixed(3)}
				</Box>
			) : null}
			{label && (
				<Grid
					container
					item
					justifyContent="center"
					alignItems="center"
					xs={2}
				>
					<Box
						component="div"
						sx={{
							transformOrigin: "50% 50%",
							transform: "rotate(-90deg)",
						}}
					>
						{label.toLocaleUpperCase()}
					</Box>
				</Grid>
			)}
		</Grid>
	);
};
