import { combineReducers, configureStore } from "@reduxjs/toolkit";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from "redux-persist";

import storage from "redux-persist/lib/storage";
import { api } from "services";
import { rtkQueryErrorsMiddleware } from "./middleware";
import userReducer from "./user";
import errorReducer from "./error";

const persistConfig = {
	key: "root",
	whitelist: ["user"],
	storage,
	stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
	[api.reducerPath]: api.reducer,
	user: userReducer,
	error: errorReducer,
});

export const store = configureStore({
	reducer: persistReducer(persistConfig as any, rootReducer),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		})
			.concat(api.middleware)
			.concat(rtkQueryErrorsMiddleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
