import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { Line } from "components";
import { sigPointsNum, sigPointsTs } from "./SignificantPoints";

import type {
	ChartPoint,
	CrossHairsProps,
	HorizontalGridLinesProps,
	VerticalGridLinesProps,
} from "types";
import type { Mesh } from "three";

const style = {
	color: "#333D61",
	lineWidth: 0.5,
	dashed: false,
};

const crossHairsStyle = {
	color: "#6357a8",
	lineWidth: 0.5,
	dashed: false,
};

// It should not need this but it satisfies TS and prevents runtime errors if its missing
const defaultScaleFactor = {
	translateX: 0,
	translateY: 0,
	scaleX: 1,
	scaleY: 1,
};

export const CrossHairs = ({
	mousePos,
	bounds,
	axisHelpers,
}: CrossHairsProps): ReactNode => {
	const ref = useRef<Mesh>(null);
	const getPointsX = (x: number, y: number, minX: number, maxX: number) => {
		return [
			{ x: minX / 2, y, z: 0 },
			{ x: maxX * 2, y, z: 0 },
		];
	};
	
	const getPointsY = (x: number, y: number, minY: number, maxY: number) => {
		return [
			{ x, y: minY / 2, z: 0 },
			{ x, y: maxY * 2, z: 0 },
		];
	};
	
	const [pointsX, setPointsX] = useState<ChartPoint[]>();
	const [pointsY, setPointsY] = useState<ChartPoint[]>();
	
	useFrame((state) => {
		const {
			size: { width, height },
			camera: { zoom },
		} = state;
		
		if (!ref.current?.children) return;
		
		const [xAxis, yAxis] = ref.current.children;
		
		if (!xAxis || !yAxis || !mousePos) return;
		
		const { layerX, layerY } = mousePos;
		
		yAxis.position.x = (layerX - width / 2) / zoom;
		xAxis.position.y = (height / 2 - layerY) / zoom;
	});
	
	useEffect(() => {
		if (!axisHelpers) return;
		
		const { xMin, xMax, yMin, yMax } = axisHelpers;
		const { minX, maxX, minY, maxY } = bounds;
		
		const x = (xMin + xMax) / 2;
		const y = (yMin + yMax) / 2;
		
		setPointsX(getPointsX(x, y, minX, maxX));
		setPointsY(getPointsY(x, y, minY, maxY));
	}, [axisHelpers, bounds]);
	
	if (!pointsX || !pointsY) return null;
	
	return (
		<mesh ref={ref}>
			<Line
				style={crossHairsStyle}
				points={pointsX}
				objId={`CrossHairX`}
				scaleFactor={axisHelpers?.scaleFactor || defaultScaleFactor}
			/>
			<Line
				style={crossHairsStyle}
				points={pointsY}
				objId={`CrossHairY`}
				scaleFactor={axisHelpers?.scaleFactor || defaultScaleFactor}
			/>
		</mesh>
	);
};

export const VerticalGridLines = ({
	maxSteps,
	axisHelpers,
	bounds,
}: VerticalGridLinesProps): ReactNode => {
	const { scaleFactor, xMin, xMax } = axisHelpers || {};
	
	const points = useMemo(() => {
		if (!xMin || !xMax) return [];
		
		return sigPointsTs({
			minV: xMin,
			maxV: xMax,
			maxDivisions: maxSteps,
		});
	}, [xMin, xMax, maxSteps]);
	
	return (
		<>
			{points.map((x, stepIndex) => {
				return (
					<Line
						style={style}
						points={[
							{ x, y: bounds?.minY / 2, z: 0 },
							{ x, y: bounds?.maxY * 2, z: 0 },
						]}
						key={`GridY${stepIndex}`}
						objId={`GridY${stepIndex}`}
						scaleFactor={scaleFactor || defaultScaleFactor}
					/>
				);
			})}
		</>
	);
};

export const HorizontalGridLines = ({
	maxSteps,
	axisHelpers,
	bounds,
}: HorizontalGridLinesProps): ReactNode => {
	const { scaleFactor, yMin, yMax } = axisHelpers || {};
	
	const points = useMemo(() => {
		if (!yMax || !yMin) return [];
		
		return sigPointsNum({
			minV: yMin,
			maxV: yMax,
			maxDivisions: maxSteps,
		});
	}, [yMin, yMax, maxSteps]);
	
	return (
		<>
			{points.map((y, stepIndex) => {
				return (
					<Line
						style={style}
						points={[
							{ x: bounds?.minX / 2, y: Number(y), z: 0 },
							{ x: bounds?.maxX * 2, y: Number(y), z: 0 },
						]}
						key={`GridX${stepIndex}`}
						objId={`GridX${stepIndex}`}
						scaleFactor={scaleFactor || defaultScaleFactor}
					/>
				);
			})}
		</>
	);
};
