import CssBaseline from "@mui/material/CssBaseline";
import { default as Layout } from "components/common/layouts/Layout/Layout";
import {
	AssetsProvider,
	ReduxProvider,
	ThemeProvider,
	ToastProvider,
} from "context";

import type { ReactNode } from "react";
import "App.css";

const App = (): ReactNode => {
	return (
		<ReduxProvider>
			<ToastProvider>
				<AssetsProvider>
					<ThemeProvider>
						<CssBaseline />
						<Layout />
					</ThemeProvider>
				</AssetsProvider>
			</ToastProvider>
		</ReduxProvider>
	);
};

export default App;
