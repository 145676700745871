import { Text } from "@react-three/drei";

import {
	CHART_OBJECT_ANNOTATION_DEFAULTS,
	CHART_OBJECT_DEFAULTS,
} from "consts";

import type { ReactNode } from "react";
import type { ChartAnnotationObject, ScaledObject } from "types";

type AnnotationProps = ChartAnnotationObject & ScaledObject;

const defaultProps = {
	font: CHART_OBJECT_ANNOTATION_DEFAULTS.font,
	fontSize: CHART_OBJECT_ANNOTATION_DEFAULTS.fontSize,
	scale: CHART_OBJECT_DEFAULTS.scale,
	rotation: CHART_OBJECT_DEFAULTS.rotation,
	style: CHART_OBJECT_ANNOTATION_DEFAULTS.style,
};

const SCALE_Y_ADJUST = 500.0;

const Annotation = ({
	text,
	font,
	fontSize,
	position,
	rotation,
	style,
	scaleFactor,
}: AnnotationProps): ReactNode => {
	const calcX = (x: number) => scaleFactor.translateX + scaleFactor.scaleX * x;
	const calcY = (y: number) => scaleFactor.translateY + scaleFactor.scaleY * y;
	
	return (
		<Text
			scale={[
				scaleFactor.scaleY / SCALE_Y_ADJUST,
				scaleFactor.scaleY / SCALE_Y_ADJUST,
				0,
			]}
			position={[calcX(position.x), calcY(position.y), position.z || 0]} // default param is 0
			rotation={[rotation.x, rotation.y, rotation.z]}
			color={style.color || defaultProps.style.color}
			font={style.font || font}
			fontSize={style.fontSize || fontSize}
			fillOpacity={style.fillOpacity || defaultProps.style.fillOpacity}
			outlineColor={style.outlineColor || defaultProps.style.outlineColor}
			outlineWidth={style.outlineWidth || defaultProps.style.outlineWidth}
		>
			{text}
		</Text>
	);
};

Annotation.defaultProps = defaultProps;

export default Annotation;
