import { type ReactNode, useState } from "react";
import {
	ListAlt as ListAltIcon,
	Timeline as TimelineIcon,
} from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";

import { CHARTS, COMPARE_COLORS, TABLE } from "consts";
import {
	GridContainer,
	LatestMetricsTable,
	MetricsChartContainer,
	TabButtons,
} from "components";

import type { ExtendedMetrics } from "containers";
import type { Run } from "types";

interface Props {
	metrics: ExtendedMetrics;
	comparedRuns: Run[];
	isLoading: boolean;
}

const Metrics = ({ metrics, comparedRuns, isLoading }: Props): ReactNode => {
	const theme = useTheme();
	
	const getColorHex = (colorKey: string): string =>
		colorKey
			.split(".")
			.reduce((value, key) => value && (value as any)[key], theme.palette)
			?.toString() || "";
	
	const [currentMetricsTab, setCurrentMetricsTab] = useState(TABLE);
	
	return (
		<Grid container item direction="column">
			<Grid item>
				<Typography variant="h2">Metrics</Typography>
			</Grid>
			<Grid item>
				<TabButtons
					currentTab={currentMetricsTab}
					handleTabChange={(newTab) => setCurrentMetricsTab(newTab)}
					tabs={[
						{
							label: CHARTS,
							icon: TimelineIcon,
						},
						{
							label: TABLE,
							icon: ListAltIcon,
						},
					]}
				/>
			</Grid>
			{currentMetricsTab === CHARTS && (
				<MetricsChartContainer
					metrics={comparedRuns.map((run, index) => {
						return {
							lineId: run.runId,
							metricsEvents: metrics[run.runId] || [],
							lineColor: getColorHex(COMPARE_COLORS[index]),
						};
					})}
				/>
			)}
			{currentMetricsTab === TABLE && (
				<GridContainer>
					<LatestMetricsTable
						metrics={metrics}
						isLoading={isLoading}
					/>
				</GridContainer>
			)}
		</Grid>
	);
};

export default Metrics;
