import * as THREE from "three";
import { Svg } from "@react-three/drei";

import { CHART_OBJECT_SYMBOL_DEFAULTS } from "consts";

import type { ReactNode } from "react";
import type { ChartSymbolObject, ScaledObject } from "types";

type SymbolProps = ChartSymbolObject & ScaledObject;

const defaultProps = {
	scale: CHART_OBJECT_SYMBOL_DEFAULTS.scale,
	rotation: CHART_OBJECT_SYMBOL_DEFAULTS.rotation,
	style: CHART_OBJECT_SYMBOL_DEFAULTS.style,
};

const Symbol = ({
	objId,
	position,
	file,
	style,
	scaleFactor,
}: SymbolProps): ReactNode => {
	const calcX = (x: number) => scaleFactor.translateX + scaleFactor.scaleX * x;
	const calcY = (y: number) => scaleFactor.translateY + scaleFactor.scaleY * y;
	
	return (
		<>
			<Svg
				src={"/" + file}
				key={objId}
				position={[calcX(position.x), calcY(position.y), position.z || 0]}
				scale={1}
				fillMaterial={new THREE.MeshBasicMaterial({ color: new THREE.Color(style.fillColor) })}
				strokeMaterial={new THREE.MeshBasicMaterial({ color: new THREE.Color(style.outlineColor) })}
			/>
		</>
	);
};

Symbol.defaultProps = defaultProps;

export default Symbol;
