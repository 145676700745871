import { SOCKET } from "consts";
import { SocketService, api } from ".";
import type {
	InstanceId,
	RunEvents,
	RunEventsRequest,
	Runs,
} from "types";

// Define an uppercase const for the event type, as event types from the websocket are uppercase
// TODO - Update the the front end event casing to match the backend
const SOCKET_CHART_EVENT = SOCKET.SERVICES.CHART.toUpperCase();

export const run = api
	.enhanceEndpoints({
		addTagTypes: ["run", "runs", "run_events"],
	})
	.injectEndpoints({
		overrideExisting: false,
		endpoints: (build) => ({
			getRuns: build.query<Runs, InstanceId | void>({
				query(instanceId: InstanceId) {
					return {
						url: `/instances/${instanceId}/runs`,
						method: "GET",
					};
				},
				providesTags: ["runs"],
			}),
			getRunEvents: build.query<RunEvents, RunEventsRequest>({
				query({ instanceId, runId, types = [], limit = 0, offset = 0 }: RunEventsRequest) {
					return {
						url: `/instances/${instanceId}/runs/${runId}/events
							?msgtype=${types.join(",")}&limit=${limit}&offset=${offset}`,
						method: "GET",
					};
				},
				providesTags: ["run_events"],
				onCacheEntryAdded: async(
					arg,
					{
						updateCachedData,
						cacheDataLoaded,
						cacheEntryRemoved,
						getCacheEntry,
					}
				) => {
					let subscriptionId;
					
					try {
						await cacheDataLoaded;
						subscriptionId = SocketService().subscribeToRun(
							arg?.runId ?? "",
							(error, data) => {
								if (data?.eventType === SOCKET_CHART_EVENT) {
									updateCachedData((draft: any) => {
										draft.events.push(data);
									});
								}
							}
						);
					} catch {}
					
					await cacheEntryRemoved;
					
					if (subscriptionId) {
						SocketService().unsubscribeToRun(arg?.runId ?? "", subscriptionId);
					}
				},
			}),
		}),
	});

export const { useGetRunsQuery, useGetRunEventsQuery, useLazyGetRunEventsQuery } = run;
