import * as THREE from "three";
import { type ReactNode, useRef } from "react";

import { CHART_OBJECT_CANDLE_DEFAULTS } from "consts";

import type { Candle, CandleStyle, ChartCandleObject, ScaleFactor } from "types";

type CandlesProps = {
	redcandles: Array<ChartCandleObject>,
	greencandles: Array<ChartCandleObject>,
	style: CandleStyle,
	scaleFactor: ScaleFactor,
};

const defaultProps = {
	style: CHART_OBJECT_CANDLE_DEFAULTS.style,
};

const buildCandle = (candle: Candle, scaleFactor: ScaleFactor, style: CandleStyle) => {
	const { o, c, h, l, dt_from_ms, dt_to_ms } = candle; // eslint-disable-line camelcase
	
	const calcX = (x: number) => scaleFactor.translateX + scaleFactor.scaleX * x;
	const calcY = (y: number) => scaleFactor.translateY + scaleFactor.scaleY * y;
	
	const shape = new THREE.Shape();
	const from = dt_from_ms / 1000; // eslint-disable-line camelcase
	const to = dt_to_ms / 1000; // eslint-disable-line camelcase
	const bodyWidth = (to - from) * 0.25;
	const wickWidth = bodyWidth * style.wickWidthScale;
	
	const min = Math.min(o, c);
	const max = Math.max(o, c);
	
	shape.moveTo(calcX(from + wickWidth), calcY(l));
	shape.lineTo(calcX(from + wickWidth), calcY(min));
	shape.lineTo(calcX(from + wickWidth + bodyWidth / 2), calcY(min));
	shape.lineTo(calcX(from + wickWidth + bodyWidth / 2), calcY(max));
	shape.lineTo(calcX(from + wickWidth), calcY(max));
	shape.lineTo(calcX(from + wickWidth), calcY(h));
	shape.lineTo(calcX(from - wickWidth), calcY(h));
	shape.lineTo(calcX(from - wickWidth), calcY(max));
	shape.lineTo(calcX(from - wickWidth - bodyWidth / 2), calcY(max));
	shape.lineTo(calcX(from - wickWidth - bodyWidth / 2), calcY(min));
	shape.lineTo(calcX(from - wickWidth), calcY(min));
	shape.lineTo(calcX(from - wickWidth), calcY(l));
	shape.lineTo(calcX(from + wickWidth), calcY(l));
	
	return shape;
};

const Candles = ({
	scaleFactor,
	redcandles,
	greencandles,
	style,
}: CandlesProps): ReactNode => {
	const ref = useRef(null);
	
	let greenShapes = (greencandles ?? []).map((obj) =>
		buildCandle(obj.candle, scaleFactor, style)
	);
	
	let redShapes = (redcandles ?? []).map((obj) =>
		buildCandle(obj.candle, scaleFactor, style)
	);
	
	const extrudeSettings = {
		steps: 1,
		depth: 1,
		bevelEnabled: false,
	};
	const greenGeometry = new THREE.ExtrudeGeometry(
		greenShapes,
		extrudeSettings
	);
	const redGeometry = new THREE.ExtrudeGeometry(redShapes, extrudeSettings);
	
	return (
		<>
			<group position={[0, 0, 0]}>
				<mesh geometry={greenGeometry} ref={ref}>
					<meshStandardMaterial color={style.positiveCandleColor} />
				</mesh>
				<mesh geometry={redGeometry} ref={ref}>
					<meshStandardMaterial color={style.negativeCandleColor} />
				</mesh>
			</group>
		</>
	);
};

Candles.defaultProps = defaultProps;

export default Candles;
